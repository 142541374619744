<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33 66C14.8032 66 0 51.1968 0 33C0 14.8032 14.8032 0 33 0C51.1968 0 66 14.8032 66 33C66 51.1968 51.1968 66 33 66ZM33 2.96352C16.4372 2.96352 2.96352 16.4372 2.96352 33C2.96352 49.5628 16.4372 63.0365 33 63.0365C49.5628 63.0365 63.0365 49.5628 63.0365 33C63.0365 16.4372 49.5628 2.96352 33 2.96352Z"
      :fill="colorTextToHex(color)"
    />
    <path
      d="M50.7113 33C50.7113 41.835 44.2389 49.1635 35.7764 50.493V38.1594H39.8163L40.6148 33H35.7764V29.2503C35.7764 27.9991 36.791 26.9865 38.0382 26.9865H40.9255V22.3169H35.7312C32.7018 22.3169 30.2425 24.7742 30.2425 27.8056V33.0021H25.6449V38.1615H30.2425V50.4992C21.7697 49.1738 15.2891 41.8453 15.2891 33.0021C15.2891 23.2204 23.2185 15.2909 33.0002 15.2909C42.7819 15.2909 50.7113 23.2204 50.7113 33.0021V33Z"
      :fill="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 66
const DEFAULT_HEIGHT = 66

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
