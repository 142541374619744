<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3103 0.689667C34.8512 0.689667 41.6788 3.74749 46.6208 8.68952C51.5635 13.6315 54.6207 20.4598 54.6207 28C54.6207 35.5409 51.5635 42.3685 46.6208 47.3105C41.6788 52.2532 34.8512 55.3104 27.3103 55.3104C19.7701 55.3104 12.9419 52.2532 7.99986 47.3105C3.05783 42.3685 0 35.5409 0 28C0 20.4598 3.05783 13.6315 7.99986 8.68952C12.9419 3.74749 19.7701 0.689667 27.3103 0.689667ZM9.62499 44.8565C10.2376 41.8075 11.7526 39.0747 13.8755 36.9532C16.681 34.1503 20.5501 32.4096 24.8033 32.4096H29.8174C34.0706 32.4096 37.9396 34.1503 40.7452 36.9532C42.8688 39.0747 44.3837 41.8075 44.9957 44.8565C49.1748 40.4734 51.7417 34.5366 51.7417 28C51.7417 21.2533 49.0069 15.1438 44.5864 10.724C40.1659 6.3035 34.0577 3.56866 27.3103 3.56866C20.5637 3.56866 14.4548 6.3035 10.0343 10.724C5.61384 15.1445 2.879 21.2533 2.879 28C2.879 34.5366 5.44589 40.4734 9.62499 44.8565ZM42.4111 47.2078C42.2371 44.0133 40.8574 41.1214 38.7216 38.9877C36.4376 36.7057 33.2852 35.2886 29.8174 35.2886H24.8033C21.3354 35.2886 18.1831 36.7057 15.8991 38.9877C13.7633 41.1214 12.3836 44.0133 12.2096 47.2078C16.3655 50.4792 21.6101 52.4314 27.3103 52.4314C33.0112 52.4314 38.2552 50.4792 42.4111 47.2078ZM27.3103 11.4747C29.965 11.4747 32.3714 12.5518 34.1121 14.2925C35.8528 16.0332 36.9299 18.4396 36.9299 21.0942C36.9299 23.7488 35.8528 26.1553 34.1121 27.896C32.3714 29.6367 29.965 30.7138 27.3103 30.7138C24.6557 30.7138 22.2493 29.6367 20.5086 27.896C18.7679 26.1553 17.6901 23.7488 17.6901 21.0942C17.6901 18.4396 18.7672 16.0332 20.5079 14.2925C22.4826 12.477 24.5823 11.4747 27.3103 11.4747ZM32.0776 16.327C30.8584 15.1078 29.1721 14.3537 27.3103 14.3537C25.4792 14.3537 23.8194 15.0806 22.6056 16.259C21.294 17.6699 20.5691 19.1291 20.5691 21.0942C20.5691 22.956 21.3239 24.6423 22.5431 25.8615C23.7623 27.0807 25.4486 27.8348 27.3103 27.8348C29.1721 27.8348 30.8584 27.0807 32.0776 25.8615C33.2968 24.6423 34.0509 22.956 34.0509 21.0942C34.0509 19.2325 33.2968 17.5461 32.0776 16.327Z"
      :fill="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 55
const DEFAULT_HEIGHT = 56

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
