export const useStoreLayout = defineStore('layout', {
  state: () => ({
    isContactDialogOpen: false,
    isMobileNotificationsDrawerOpen: false,
  }),
  getters: {
    breadcrumbs: () => {
      const { breadcrumbs } = useBreadcrumbs()
      return breadcrumbs.value
    },
    breadcrumbsCount(): number {
      return this.breadcrumbs.length
    },
    hasBreadcrumbs(): boolean {
      return this.breadcrumbsCount > 0
    },
    breadcrumbsBackgroundColor() {
      const route = useRoute()
      return route.meta.breadcrumbsBackgroundColor
    },
    appBarCssClasses() {
      const classes = ['v-app-bar--with-breadcrumbs']
      if (this.breadcrumbsBackgroundColor) {
        classes.push(
          `v-app-bar--extension-bg-${this.breadcrumbsBackgroundColor}`,
        )
      }
      return classes
    },

    pageBackgroundImagePath() {
      const route = useRoute()
      return route.meta.pageBackgroundImagePath
    },
  },
  actions: {
    openDialogContact() {
      this.isContactDialogOpen = true
    },
    closeDialogContact() {
      this.isContactDialogOpen = false
    },

    openMobileNotificationsDrawer() {
      this.isMobileNotificationsDrawerOpen = true
    },
    closeMobileNotificationsDrawer() {
      this.isMobileNotificationsDrawerOpen = false
    },
    toggleMobileNotificationsDrawer() {
      this.isMobileNotificationsDrawerOpen =
        !this.isMobileNotificationsDrawerOpen
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreLayout, import.meta.hot))
}
