<template>
  <div class="d-flex">
    <v-menu>
      <template #activator="{ props }">
        <v-btn
          variant="text"
          icon
          rounded
          class="mr-3"
          v-bind="props"
        >
          <IconProfileSvg
            :height="30"
            color="white"
          />
        </v-btn>
      </template>
      <AppHeaderRightMenuProfile />
    </v-menu>

    <v-menu v-model="isMenuOpenNotifications">
      <template #activator="{ props }">
        <v-btn
          :loading="status !== 'success'"
          variant="text"
          icon
          rounded
          class="mr-3"
          v-bind="props"
        >
          <IconBellSvg
            :height="30"
            color="white"
            :active="
              status === 'success' && data.not_archived_messages_count > 0
            "
            active-color="tertiary"
          />
        </v-btn>
      </template>
      <AppHeaderRightMenuNotifs
        v-if="isMenuOpenNotifications"
        :notifications-count="
          status === 'success' ? data.not_archived_messages_count : 0
        "
      />
    </v-menu>

    <v-btn
      :loading="card.isLoading"
      :disabled="!card.isLoading"
      variant="text"
      icon
      rounded
      class="mr-3 opacity-initial text-white"
    >
      <IconStarSvg
        :height="30"
        color="white"
      />
      {{ card.availableStarCount }}
    </v-btn>

    <v-menu
      v-model="isMenuOpenBasket"
      :close-on-content-click="false"
    >
      <template #activator="{ props }">
        <v-btn
          :loading="basket.isLoading"
          variant="text"
          icon
          rounded
          class="text-white"
          v-bind="props"
        >
          <IconBasketSvg
            :height="30"
            color="white"
          />
          {{ basket.purchasesTotalQuantity }}
        </v-btn>
      </template>
      <AppHeaderRightMenuBasket @close="isMenuOpenBasket = false" />
    </v-menu>
  </div>
</template>

<script setup lang="ts">
const basket = useStoreBasket()
const card = useStoreCard()

const isMenuOpenBasket = ref(false)
const isMenuOpenNotifications = ref(false)

const { data, status } = await useAsyncData(
  'notifications',
  () =>
    useNuxtApp().$PE.client.getTimeline({
      skipLimit: true,
      streamClassNames: ['Timeline::Streams::LustucruGnocchiAnniversary'],
      selectEditorReceiptsOnly: true,
    }),
  { server: false, lazy: true },
)

onMounted(() => {
  card.makeSureCardIsLoaded()
  basket.makeSureBasketIsLoaded()
})
</script>
