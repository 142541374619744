import validate from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45legacy_45urls_45global from "/home/deploy/lustucru-gnocchi/middleware/01.redirect-legacy-urls.global.ts";
import _02_45redirect_45game_45pages_45global from "/home/deploy/lustucru-gnocchi/middleware/02.redirect-game-pages.global.ts";
import authenticated_45global from "/home/deploy/lustucru-gnocchi/middleware/authenticated.global.ts";
import not_45authenticated_45global from "/home/deploy/lustucru-gnocchi/middleware/not-authenticated.global.ts";
import reset_45game2_45when_45done_45global from "/home/deploy/lustucru-gnocchi/middleware/reset-game2-when-done.global.ts";
import reset_45order_45after_45done_45global from "/home/deploy/lustucru-gnocchi/middleware/reset-order-after-done.global.ts";
import manifest_45route_45rule from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45legacy_45urls_45global,
  _02_45redirect_45game_45pages_45global,
  authenticated_45global,
  not_45authenticated_45global,
  reset_45game2_45when_45done_45global,
  reset_45order_45after_45done_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}