import mockedData from '@/utils/mocked-data/notifs'

export interface Notification {
  time: string
  text: string
  class: string
}

export const useStoreNotifications = defineStore('notifications', () => {
  const isLoading = ref(false)
  const isLoaded = ref(false)
  const notifications = ref([] as Notification[])

  const notificationsCount = computed(() => notifications.value.length)
  const hasNotifications = computed(() => notificationsCount.value > 0)

  function $reset() {
    isLoading.value = false
    isLoaded.value = false
    notifications.value = []
  }

  async function fetchNotifications() {
    if (isLoading.value) return

    isLoading.value = true

    // TODO
    await new Promise((resolve) => {
      setTimeout(resolve, 2000)
    })
    notifications.value = mockedData

    isLoading.value = false
  }

  async function makeSureNotificationsAreLoaded() {
    if (isLoaded.value) return

    return fetchNotifications()
  }

  async function makeSureNotificationsAreLoadedIfLogged() {
    const auth = useStoreAuth()
    if (!auth.isLogged) return

    return makeSureNotificationsAreLoaded()
  }

  return {
    isLoading,
    isLoaded,
    notifications,

    notificationsCount,
    hasNotifications,

    makeSureNotificationsAreLoadedIfLogged,

    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useStoreNotifications, import.meta.hot),
  )
}
