<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    class="svg"
    :style="{ fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` }"
  >
    <text
      class="shadow"
      :class="{ 'text-uppercase': textUppercase }"
      :y="computedY + shadowOffset"
      :x="x + shadowOffset"
      :style="{ strokeWidth: `${strokeWidth}px` }"
    >
      {{ text }}
    </text>
    <text
      class="outline"
      :class="{ 'text-uppercase': textUppercase }"
      :y="computedY"
      :x="x"
      :style="{ strokeWidth: `${strokeWidth}px` }"
    >
      {{ text }}
    </text>
    <text
      class="text"
      :class="{ 'text-uppercase': textUppercase }"
      :y="computedY"
      :x="x"
    >
      {{ text }}
    </text>
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  fontSize: {
    type: Number,
    required: true,
  },
  strokeWidth: {
    type: Number,
    required: true,
  },
  shadowOffset: {
    type: Number,
    required: true,
  },
  textUppercase: {
    type: Boolean,
    default: false,
  },

  y: {
    type: Number,
    default: null,
  },
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
})

const x = computed(() => 0 + props.strokeWidth)
const computedY = computed(() => props.y || props.fontSize * 0.9)
const lineHeight = computed(() => props.fontSize * 1.1)
const computedHeight = computed(() => props.height || lineHeight.value)
const computedWidth = computed(
  () =>
    props.width ||
    (props.textUppercase ? 0.7 : 0.6) * props.fontSize * props.text.length,
)
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.svg {
  font-weight: 400;
  font-family: $font-family-title !important;
  // line-height:;
}
.text {
  stroke: $color-secondary;
  fill: white;
}
.outline {
  stroke: $color-secondary;
  stroke-linejoin: round;
  stroke-linecap: round;
  fill: white;
}
.shadow {
  stroke: $color-secondary;
  stroke-linejoin: round;
  stroke-linecap: round;
  fill: white;
}
</style>
