<template>
  <v-container
    fluid
    class="pa-0 pos-relative"
  >
    <img
      class="img-20-years"
      src="~/assets/logos/20-years.svg"
    />
    <img
      class="img-pa"
      src="~/assets/images/PA.png"
    />

    <v-row
      class="z-index-0"
      no-gutters
    >
      <v-col
        cols="12"
        class="d-flex flex-column"
      >
        <LandingPagesShopAloneDesktopContent />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.pos-relative {
  position: relative;
}
.img-20-years {
  width: 175px;
  z-index: 1;
  position: absolute;
  left: 100px;
  top: 50px;
}
.img-pa {
  width: 160px;
  z-index: 1;
  position: absolute;
  right: 100px;
  top: 50px;
}
.z-index-0 {
  z-index: 0;
}
</style>
