<template>
  <ul
    class="notifs"
    :class="{ 'notifs--limit-height': limitHeight }"
  >
    <li v-if="status === 'idle'" />
    <li v-else-if="status === 'error'">
      <div>
        Une erreur est survenue
        <v-btn @click="refresh">Réessayer</v-btn>
      </div>
    </li>
    <li v-if="status === 'pending'">
      <div>Chargement…</div>
    </li>
    <li
      v-for="(notif, index) in data.messages"
      v-else-if="status === 'success'"
      :key="index"
      class="notif"
      :class="notif.class"
    >
      <div class="notif__time">
        {{ timeDurationText(notif.message_updated_at) }}
      </div>
      <div class="notif__text">
        {{ notif.short_text_for_notification }}
      </div>
    </li>
    <!-- should not happen -->
    <li v-else />
  </ul>
</template>

<script setup lang="ts">
defineProps({
  limitHeight: {
    type: Boolean,
    default: false,
  },
})
import timeDurationText from '~/utils/functions/timeDurationText'

const { data, status, refresh } = await useAsyncData(
  'notifications',
  () =>
    useNuxtApp().$PE.client.getTimeline({
      skipLimit: true,
      streamClassNames: ['Timeline::Streams::LustucruGnocchiAnniversary'],
      archiveUserTimelineMessages: true,
      selectEditorReceiptsOnly: true,
    }),
  { server: false, lazy: true },
)
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.notif-center {
  width: 376px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.notifs {
  padding: 8px;
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  overflow: auto;
}
.notifs--limit-height {
  max-height: calc(100vh - $height-app-bar - $height-notif-center-header);
}
.notif {
  background: white;
  border-radius: 4px;
  padding: 6px 12px;
}
.notif + .notif {
  margin-top: 8px;
}
</style>
