import { DateTime } from 'luxon'

const endDateTime = DateTime.fromISO(GAME_2_END_DATETIME)

export default function () {
  const isDiscontinued = ref(false)

  function update() {
    const now = DateTime.now()
    isDiscontinued.value = now > endDateTime

    if (isDiscontinued.value) {
      clearInterval(interval)
    }
  }
  const interval = setInterval(update, 1000)
  update()

  return {
    isDiscontinued,
  }
}
