<template>
  <!-- by chronological order -->

  <!-- <LandingPagesTF1 /> -->
  <!-- <LandingPagesTF1Done /> -->
  <!-- <LandingPagesTF2Teasing /> -->
  <!-- <LandingPagesTF2 /> -->
  <!-- <LandingPagesShopAlone /> -->

  <component :is="is" />
</template>

<script setup lang="ts">
import { LandingPagesTF2, LandingPagesShopAlone } from '#components'
const { isDiscontinued } = useGame2()
const is = computed(() =>
  isDiscontinued.value ? LandingPagesShopAlone : LandingPagesTF2,
)
</script>
