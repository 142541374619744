<template>
  <v-app>
    <AppHeader
      v-model="isMobileDrawerOpen"
      class="app__header"
    />
    <AppContact />

    <AppMobileNavigationDrawerMain
      v-model="isMobileDrawerOpen"
      class="hidden-sm-and-up"
    />

    <AppMobileNavigationDrawerNotifications class="hidden-sm-and-up" />

    <v-main
      class="app__main"
      :class="mainCssClasses"
    >
      <div class="position-relative fill-height">
        <AppBackground class="app__background-image hidden-xs" />
        <div class="app__above-background-image fill-height">
          <slot />
        </div>
      </div>
    </v-main>

    <AppMobileTabBar class="hidden-sm-and-up" />

    <AppFooterDesktop class="app__footer hidden-xs" />
    <AppFooterMobile class="app__footer hidden-sm-and-up" />

    <TheSnackbar />
  </v-app>
</template>

<script setup lang="ts">
const isMobileDrawerOpen = ref(false)

const { isDiscontinued } = useGame2()
const { smAndUp } = useDisplay()

const mainCssClasses = computed(() => {
  const route = useRoute()
  const classes = []
  if (route.meta.pageBackgroundColor) {
    classes.push(`bg-${route.meta.pageBackgroundColor}`)
  }
  if (route.meta.pageBackgroundCssClass) {
    classes.push(route.meta.pageBackgroundCssClass)
  }
  if (route.path === '/') {
    // LandingPagesTF1
    // if (smAndUp.value) {
    //   classes.push('bg-left-tertiary-right-primary')
    // } else {
    //   classes.push('bg-top-tertiary-bottom-primary')
    // }
    // LandingPagesTF1Done
    // nothing
    if (!isDiscontinued.value) {
      // LandingPagesTF2Teasing & LandingPagesTF2
      if (smAndUp.value) {
        classes.push('bg-left-minions-blue-light-right-primary')
      } else {
        classes.push('bg-top-minions-blue-light-bottom-primary')
      }
    } else {
      // LandingPagesShopAlone
      classes.push('bg-primary')
    }
  }
  if (route.meta.layout) {
    if (
      (route.meta.layout === 'authenticated-profile' && smAndUp.value) ||
      (route.meta.layout === 'authenticated-history' && smAndUp.value) ||
      route.meta.layout === 'eligible-products' ||
      route.meta.layout === 'faq' ||
      route.meta.layout === 'how-to-participate'
    ) {
      classes.push('bg-top-tertiary-bottom-primary')
    }
  }
  return classes
})
</script>

<style lang="scss">
@import '~/assets/styles/variables.scss';

.app__main {
  z-index: 0;
  // on mobile, padding is added by vuetify for the fixed footer
  // but we already have it in the "normal" footer
  padding-bottom: 0 !important;
}
.app__footer {
  z-index: 50;
}

.app__background-image {
  z-index: 0;
}
.app__above-background-image {
  z-index: 1;
  position: relative;
}

.bg-left-tertiary-right-primary {
  background: linear-gradient(
    to right,
    $color-tertiary 50%,
    $color-primary 50%
  ) !important;
}
.bg-top-tertiary-bottom-primary {
  background: linear-gradient(
    to bottom,
    $color-tertiary 50%,
    $color-primary 50%
  ) !important;
}
.bg-top-primary-bottom-tertiary {
  background: linear-gradient(
    to bottom,
    $color-primary 50%,
    $color-tertiary 50%
  ) !important;
}

.bg-left-minions-blue-light-right-primary {
  background: linear-gradient(
    to right,
    $color-minions-blue-light 50%,
    $color-primary 50%
  ) !important;
}
.bg-top-minions-blue-light-bottom-primary {
  background: linear-gradient(
    to bottom,
    $color-minions-blue-light 50%,
    $color-primary 50%
  ) !important;
}
.bg-top-minions-blue-dark-bottom-tertiary {
  background: linear-gradient(
    to bottom,
    $color-minions-blue-dark 50%,
    $color-tertiary 50%
  ) !important;
}
.bg-top-tertiary-bottom-minions-blue-dark {
  background: linear-gradient(
    to bottom,
    $color-tertiary 50%,
    $color-minions-blue-dark 50%
  ) !important;
}
</style>
