<template>
  <v-container class="pa-0 breadcrumbs">
    <template
      v-for="(link, index) in layout.breadcrumbs"
      :key="index"
    >
      <nuxt-link
        v-if="link.to"
        :to="link.to"
        class="breadcrumbs__link"
      >
        {{ link.text }}
      </nuxt-link>
      <span
        v-else
        class="breadcrumbs__text"
      >
        {{ link.text }}
      </span>

      <span v-show="index !== layout.breadcrumbsCount - 1">&nbsp;/&nbsp;</span>
    </template>
  </v-container>
</template>

<script setup lang="ts">
const layout = useStoreLayout()
</script>

<style scoped>
.breadcrumbs {
  font-size: 12px;
}
.breadcrumbs__link {
  color: inherit;
  font-weight: 900;
  text-decoration: none;
}
.breadcrumbs__text {
  font-weight: 500;
}
</style>
