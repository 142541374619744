<template>
  <div class="position-relative fill-height">
    <img
      class="pot-holder-blue"
      src="~/assets/images/landing-pages/5-shop-alone/mobile/pot-holder-blue.png"
      srcset="
        ~/assets/images/landing-pages/5-shop-alone/mobile/pot-holder-blue.png    1x,
        ~/assets/images/landing-pages/5-shop-alone/mobile/pot-holder-blue@2x.png 2x,
        ~/assets/images/landing-pages/5-shop-alone/mobile/pot-holder-blue@3x.png 3x
      "
    />
    <img
      class="img-20-years"
      src="~/assets/logos/20-years.svg"
    />

    <v-img
      class="bg-primary align-center fill-height"
      src="~/assets/images/landing-pages/5-shop-alone/mobile/confettis.png"
      :srcset="`
        ${Bg1X} 1x,
        ${Bg2X} 2x,
        ${Bg3X} 3x
      `"
    >
      <div class="content">
        <div class="outlined mb-2 bg-primary text-white">
          Jusqu'au 31/01/2026
        </div>

        <div class="mt-1">
          <AppTitle
            text="La Grande"
            :width="265"
            :height="48"
            :y="38"
            :font-size="48"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>
        <div class="mb-1 mt-n2">
          <AppTitle
            text="Boutique"
            :width="245"
            :height="54"
            :y="38"
            :font-size="48"
            :stroke-width="5"
            :shadow-offset="2"
            text-uppercase
          />
        </div>

        <div>
          <AppButtonTertiary
            :to="localePath('shop')"
            width="180px"
            class="font-size-16px"
          >
            Visiter la Boutique
          </AppButtonTertiary>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script setup lang="ts">
import Bg1X from '~/assets/images/landing-pages/5-shop-alone/mobile/confettis.png'
import Bg2X from '~/assets/images/landing-pages/5-shop-alone/mobile/confettis@2x.png'
import Bg3X from '~/assets/images/landing-pages/5-shop-alone/mobile/confettis@3x.png'
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.pot-holder-blue {
  z-index: 1;
  position: absolute;
  bottom: -80px;
  left: 0;
}
.img-20-years {
  z-index: 1;
  position: absolute;
  width: 75px;
  bottom: 20px;
  right: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined {
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  padding: 0 5px;
}
</style>
