<template>
  <LayoutAuthenticated>
    <div class="hidden-xs bg-tertiary">
      <v-container class="pa-sm-0 pt-sm-5 position-relative">
        <v-row>
          <v-col class="pl-1">
            <div>
              <nuxt-link
                :to="localePath('profile')"
                class="text-decoration-none"
              >
                <v-icon>mdi-chevron-left</v-icon>
                <span class="text-decoration-underline">Mon compte</span>
              </nuxt-link>
            </div>
          </v-col>
        </v-row>
        <v-row class="pb-sm-0 mx-0 mb-0 border-bottom-white">
          <v-col class="pl-0 text-start">
            <AppTitle
              text="Mon compte"
              :width="200"
              :height="40"
              :y="26"
              :font-size="32"
              :stroke-width="5"
              :shadow-offset="2"
              class="hidden-xs"
            />
            <AppTitle
              text="Mon compte"
              :width="170"
              :height="34"
              :y="22"
              :font-size="28"
              :stroke-width="4"
              :shadow-offset="2"
              class="hidden-sm-and-up"
            />
          </v-col>
          <v-col
            v-if="card.availableStarCount"
            class="pr-0 text-end hidden-xs"
          >
            <div class="d-flex justify-end align-center">
              <img src="~/assets/images/profile/star.png" />
              <AppTitle
                :text="String(card.availableStarCount)"
                :width="50"
                :height="34"
                :y="26"
                :font-size="32"
                :stroke-width="5"
                :shadow-offset="2"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="pt-4 d-flex justify-center overflow-y-hidden">
        <div class="position-relative">
          <AppProfileTabs>
            <AppProfileTab
              title="Mon profil"
              :to="{ path: localePath('profile-infos') }"
            />
            <AppProfileTab
              title="Mes adresses"
              :to="{ path: localePath('profile-addresses') }"
            />
            <AppProfileTab
              title="Connexion et sécurité"
              :to="{ path: localePath('profile-security') }"
            />
          </AppProfileTabs>

          <img
            src="~/assets/images/profile/layout/pot-holder-blue.png"
            class="img--pot-holder-blue"
          />
          <img
            src="~/assets/images/profile/layout/pot-holder-red.png"
            class="img--pot-holder-red"
          />
        </div>
      </div>
    </div>
    <div class="position-relative bg-primary overflow-hidden">
      <img
        src="/images/confettis/profile-main-layout.png"
        class="z-index-0 hidden-xs img--background"
      />
      <div class="position-relative z-index-1">
        <slot />
      </div>
    </div>
  </LayoutAuthenticated>
</template>

<script setup lang="ts">
import LayoutAuthenticated from '../authenticated.vue'

const card = useStoreCard()
const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
.img--pot-holder-blue {
  position: absolute;
  bottom: -290px;
  left: -380px;
}
.img--pot-holder-red {
  position: absolute;
  bottom: -390px;
  right: -230px;
}

.img--background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}
.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
</style>
