import { DEFAULT_ROUTE_NAME_AFTER_AUTH } from '@/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  const store = useStoreAuth()
  const localePath = useLocalePath({ route: to })

  if (to.name && String(to.name).startsWith('auth') && store.isLogged) {
    return navigateTo(
      (to.query.r as string) || localePath(DEFAULT_ROUTE_NAME_AFTER_AUTH),
    )
  }
})
