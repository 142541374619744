import revive_payload_client_4sVQNw7RlN from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_ftx86CUdf5 from "/home/deploy/lustucru-gnocchi/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_RMzWu406ID from "/home/deploy/lustucru-gnocchi/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_KgADcZ0jPj from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/deploy/lustucru-gnocchi/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/deploy/lustucru-gnocchi/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_wMKVLl9fyU from "/home/deploy/lustucru-gnocchi/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import composition_sLxaNGmlSL from "/home/deploy/lustucru-gnocchi/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/deploy/lustucru-gnocchi/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/deploy/lustucru-gnocchi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import add_purchease_kit_to_store_client_blt0DkTDFx from "/home/deploy/lustucru-gnocchi/plugins/add-purchease-kit-to-store.client.ts";
import airbrake_client_nQGVwm8YNb from "/home/deploy/lustucru-gnocchi/plugins/airbrake.client.ts";
import maska_UHaKf2z1iQ from "/home/deploy/lustucru-gnocchi/plugins/maska.ts";
import prevent_resize_on_safari_mobile_client_sIGvYnR5Po from "/home/deploy/lustucru-gnocchi/plugins/prevent-resize-on-safari-mobile.client.ts";
import seo_6xeD0pAsTm from "/home/deploy/lustucru-gnocchi/plugins/seo.ts";
import tarteaucitronjs_client_DaU5rHR4no from "/home/deploy/lustucru-gnocchi/plugins/tarteaucitronjs.client.ts";
import update_session_with_basket_client_8eoXRgBNRS from "/home/deploy/lustucru-gnocchi/plugins/update-session-with-basket.client.ts";
import vuetify_7h9QAQEssH from "/home/deploy/lustucru-gnocchi/plugins/vuetify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/home/deploy/lustucru-gnocchi/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_i18n_ftx86CUdf5,
  vuetify_icons_RMzWu406ID,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_wMKVLl9fyU,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  add_purchease_kit_to_store_client_blt0DkTDFx,
  airbrake_client_nQGVwm8YNb,
  maska_UHaKf2z1iQ,
  prevent_resize_on_safari_mobile_client_sIGvYnR5Po,
  seo_6xeD0pAsTm,
  tarteaucitronjs_client_DaU5rHR4no,
  update_session_with_basket_client_8eoXRgBNRS,
  vuetify_7h9QAQEssH,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]