<template>
  <div
    v-if="layout.pageBackgroundImagePath"
    class="wrapper-background-image"
  >
    <img
      :src="layout.pageBackgroundImagePath"
      class="background-image"
    />
  </div>
</template>

<script setup lang="ts">
const layout = useStoreLayout()
</script>

<style lang="scss" scoped>
.wrapper-background-image {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.background-image {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
}
</style>
