

export const isDev = false
export function vuetifyConfiguration() {
  const options = JSON.parse('{"display":{"mobileBreakpoint":"sm"},"theme":{"defaultTheme":"myCustomLightTheme","themes":{"myCustomLightTheme":{"dark":false,"colors":{"on-surface":"#20206C","primary":"#12BAEE","on-primary":"#20206C","secondary":"#20206C","on-secondary":"#FFFFFF","error":"#FF0000","tertiary":"#FEC337","on-tertiary":"#20206C","quaternary":"#F16D04","on-quaternary":"#20206C","wireframe-1":"#D9D9D9","wireframe-2":"#777777","wireframe-3":"#EFEFEF","wireframe-4":"#F2F2F2","wireframe-5":"#F4F4F4","white":"#FFFFFF","minions-blue-dark":"#1b9dd9","minions-blue-light":"#99d9f5","on-minions-blue-light":"#20206C","minions-yellow":"#ffdd00","on-minions-yellow":"#20206C","minions-rose":"#ed1185"}}}},"ssr":true}')
  
  
  
  
  return options
}

