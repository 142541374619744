export const appHead = {"meta":[{"charset":"utf-8"},{"name":"description","content":"Transformez vos achats Lustucru en objets collector !"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"index, follow"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Lustucru - 20 ans du Gnocchi"},{"property":"og:site:name","content":"Lustucru - 20 ans du Gnocchi"},{"property":"og:site_name","content":"Lustucru - 20 ans du Gnocchi"},{"property":"og:description","content":"Transformez vos achats Lustucru en objets collector !"},{"property":"og:locale","content":"fr_FR"},{"property":"og:image","content":"https://www.20ans-gnocchi.fr/og-image.png"},{"property":"og:image:secure_url","content":"https://www.20ans-gnocchi.fr/og-image.png"},{"property":"og:image:width","content":"1280"},{"property":"og:image:height","content":"800"},{"property":"og:image:alt","content":"Lustucru - 20 ans du Gnocchi"},{"property":"og:image:type","content":"image/png"},{"name":"twitter:title","content":"Lustucru - 20 ans du Gnocchi"},{"name":"twitter:description","content":"Transformez vos achats Lustucru en objets collector !"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@LustucruSelect"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[{"src":"/scripts/tarteaucitron.js-1.17.0/tarteaucitron.min.js"}],"noscript":[],"htmlAttrs":{"lang":"fr"},"title":"Lustucru - 20 ans du Gnocchi"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'