import { compact, flatMap, sumBy } from 'lodash-es'

import type { User } from '@/stores/auth'
import type { IAddress } from '@/utils/types/core/address'

export interface Purchase {
  couponIdentifier: string
  quantity: number
}

interface PurchaseForHeader {
  identifier: string
  quantity: number
  text: string
  imageUrl: string
  value: number
}

interface PurchaseForBasketDetails extends PurchaseForHeader {}

// const purchases: Purchase[] = [
//   { couponIdentifier: '1', quantity: 2 },
//   { couponIdentifier: '2', quantity: 3 },
// ]
const purchases: Purchase[] = []

export const useStoreBasket = defineStore('basket', {
  state: () => ({
    isLoading: false,

    purchases,
    // purchases: [] as Purchase[],

    orderAddressId: null as null | number,

    orderCivility: '',
    orderEmail: '',
    orderFirstName: '',
    orderLastName: '',
    orderPhone: '',

    orderAddressName: '',
    orderAddress1: '',
    orderAddress2: '',
    orderZipcode: '',
    orderCity: '',

    orderTermsAccepted: false,
    orderNewsletterOptedIn: false,
    orderSaveAddressOnProfile: false,

    isOrdering: false,
    orderingErrorText: null as null | string,
    hasOrderBeenDone: false,
  }),
  getters: {
    purchasesTotalQuantity: (state) => sumBy(state.purchases, 'quantity'),
    isEmpty(): boolean {
      return this.purchasesTotalQuantity === 0
    },
    purchasesForOrder(state) {
      return flatMap(state.purchases, (p) =>
        Array.from({ length: p.quantity }, () => p.couponIdentifier),
      )
    },
    purchasesForHeader(state) {
      const shop = useStoreShop()
      if (!shop.catalog) return []

      return compact(
        state.purchases.map((p) => {
          const index = shop.catalog!.coupons.findIndex(
            (c) => c.identifier === p.couponIdentifier,
          )
          if (index === -1) return

          const coupon = shop.catalog!.coupons[index]
          return {
            identifier: coupon.identifier,
            quantity: p.quantity,
            text: coupon.name,
            imageUrl:
              coupon.image_3x_url ||
              coupon.image_2x_url ||
              coupon.image_1x_url ||
              coupon.image_url,
            value: coupon.value,
          } as PurchaseForHeader
        }),
      )
    },
    purchasesTotalValue(): number {
      return sumBy(this.purchasesForHeader, (p) => p.quantity * p.value)
    },
    isPurchasesTotalValuePlural(): boolean {
      return Math.abs(this.purchasesTotalValue) >= 2
    },
    purchasesForBasketDetails(): PurchaseForBasketDetails[] {
      return this.purchasesForHeader
    },
    remainingStarCount(): null | number {
      const card = useStoreCard()
      if (card.availableStarCount === null) return null

      return card.availableStarCount - this.purchasesTotalValue
    },
    isRemainingStarCountPlural(): boolean {
      if (!this.remainingStarCount) return false

      return Math.abs(this.remainingStarCount) >= 2
    },
    hasEnoughStars(): boolean {
      const card = useStoreCard()
      if (card.availableStarCount === null) return false

      return card.availableStarCount >= this.purchasesTotalValue
    },
    hasNotEnoughStars(): boolean {
      return !this.hasEnoughStars
    },
  },
  actions: {
    updateUserDetailsWithAuth() {
      const auth = useStoreAuth()
      if (!auth.user) return

      this.updateUserDetails(auth.user)
    },
    updateUserDetails(user: User) {
      this.orderCivility = user.title || ''
      this.orderEmail = user.email
      this.orderFirstName = user.firstName
      this.orderLastName = user.lastName
      this.orderPhone = user.phone || ''
    },
    updateAddressDetails(address: IAddress) {
      this.orderAddressName = address.user_defined_identifier
      this.orderAddress1 = address.address_1
      this.orderAddress2 = address.address_2
      this.orderZipcode = address.postal_code
      this.orderCity = address.city
    },

    async makeSureBasketIsLoaded() {
      if (this.purchases.length === 0) return

      this.isLoading = true

      const shop = useStoreShop()
      try {
        await shop.makeSureShopIsLoaded()
      } catch (error) {
        useNuxtApp().$airbrakeNotify({
          error,
          context: { locator: 'makeSureBasketIsLoaded' },
        })
      } finally {
        this.isLoading = false
      }
    },
    async makeSureBasketIsLoadedIfLogged() {
      const auth = useStoreAuth()
      if (!auth.isLogged) return

      return this.makeSureBasketIsLoaded()
    },

    async canAddPurchase(identifier: string, quantity: number) {
      const card = useStoreCard()
      await card.makeSureCardIsLoaded()
      if (!card.availableStarCount) {
        throw new Error('[basket.canAddPurchase] no card')
      }

      const shop = useStoreShop()
      await shop.makeSureShopIsLoaded()

      const goody = shop.findGoody(identifier)
      if (!goody) {
        throw new Error('[basket.canAddPurchase] no goody')
      }

      return (
        this.purchasesTotalValue + goody.value * quantity <=
        card.availableStarCount
      )
    },
    addPurchase(identifier: string, quantity: number) {
      const index = this.purchases.findIndex(
        (p) => p.couponIdentifier === identifier,
      )
      if (index === -1) {
        this.purchases.push({ couponIdentifier: identifier, quantity })
      } else {
        this.purchases[index].quantity += quantity
      }
    },
    removePurchase(identifier: string) {
      const index = this.purchases.findIndex(
        (p) => p.couponIdentifier === identifier,
      )
      if (index === -1) return

      this.purchases.splice(index, 1)
    },
    decreasePurchaseQuantityOrRemove(identifier: string) {
      const index = this.purchases.findIndex(
        (p) => p.couponIdentifier === identifier,
      )
      if (index === -1) return

      if (this.purchases[index].quantity === 1) {
        this.purchases.splice(index, 1)
      } else {
        this.purchases[index].quantity--
      }
    },

    decreaseQuantityOfIdentifier(identifier: string) {
      const index = this.purchases.findIndex(
        (p) => p.couponIdentifier === identifier,
      )
      if (index === -1) return

      this.purchases[index].quantity--
    },
    increaseQuantityOfIdentifier(identifier: string) {
      const index = this.purchases.findIndex(
        (p) => p.couponIdentifier === identifier,
      )
      if (index === -1) return

      this.purchases[index].quantity++
    },

    async order() {
      if (this.isOrdering) return

      this.isOrdering = true
      this.orderingErrorText = null

      const size = this.purchasesForOrder.length

      const successfullOrderIndentifiers: string[] = []
      const failedOrderIndentifiers: string[] = []

      for (const identifier of this.purchasesForOrder) {
        try {
          await this.PE.client.orderGoody({
            goody: { identifier },
            userInformations: {
              user_defined_identifier: this.orderSaveAddressOnProfile
                ? this.orderAddressName
                : null,
              title: this.orderCivility,
              last_name: this.orderLastName,
              first_name: this.orderFirstName,
              external_email: this.orderEmail,
              address_1: this.orderAddress1,
              address_2: this.orderAddress2,
              postal_code: this.orderZipcode,
              city: this.orderCity,
              phone_number: this.orderPhone,
              optin_to_newsletter: this.orderNewsletterOptedIn,
            },
          })
          successfullOrderIndentifiers.push(identifier)
        } catch (error) {
          failedOrderIndentifiers.push(identifier)
        }
      }

      this.isOrdering = false

      if (failedOrderIndentifiers.length === 0) {
        this.hasOrderBeenDone = true
        return
      }

      if (successfullOrderIndentifiers.length === 0) {
        this.orderingErrorText = GENERIC_ERROR_TEXT_DETAILED
        this.airbrakeNotify({
          error: new Error('all orders failed'),
          params: { successfullOrderIndentifiers, failedOrderIndentifiers },
          context: { locator: 'order #1' },
        })
        return
      }

      // order in which we remove things does not matter
      // since we are removing by `goody.identifier`
      successfullOrderIndentifiers.forEach((identifier) => {
        this.decreasePurchaseQuantityOrRemove(identifier)
      })

      this.orderingErrorText = `
        Un erreur est survenue, certains de vos cadeaux n'ont pas pu être commandés.
        Ceux qui n'ont pas été commandés sont toujours présents dans votre panier,
        vous pouvez tenter de les commander de nouveau en cliquant sur "Valider"
      `

      this.airbrakeNotify({
        error: new Error(
          `${failedOrderIndentifiers.length} failed orders out of ${size}`,
        ),
        params: { successfullOrderIndentifiers, failedOrderIndentifiers },
        context: { locator: 'order #2' },
      })
    },

    setPurchases(purchases: Purchase[]) {
      this.purchases = purchases
    },
    updateBasketInSession() {
      $fetch('/api/updateBasketInSession', {
        method: 'PUT',
        body: {
          purchases: this.purchases,
        },
      })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreBasket, import.meta.hot))
}
