<template>
  <AppCard
    class="item"
    :class="{ 'item--mobile': mobile }"
  >
    <div
      class="item__left"
      :class="{ 'item__left--mobile': mobile }"
    >
      <v-img :src="imageUrl" />
    </div>
    <div
      class="item__center hidden-xs"
      :class="{ 'item__center--mobile': mobile }"
    >
      {{ text }}
    </div>
    <div class="item__center item__center--mobile hidden-sm-and-up">
      <h5 class="item__center__top">
        {{ text }}
      </h5>
      <h4 class="item__center__bottom">
        {{ quantity }} x
        <IconStarSvg
          color="tertiary"
          fill
          :height="14"
        />
        <span class="value">
          {{ value }}
        </span>
      </h4>
    </div>

    <div class="item__right hidden-xs">
      <div class="item__right__top">
        <v-icon
          v-if="removable"
          size="small"
          @click="$emit('remove')"
        >
          mdi-close
        </v-icon>
      </div>
      <div class="item__right__bottom">
        {{ quantity }} x
        <IconStarSvg
          color="tertiary"
          fill
          :height="14"
        />
        <span class="value">
          {{ value }}
        </span>
      </div>
    </div>
  </AppCard>
</template>

<script setup lang="ts">
defineProps({
  text: {
    type: String,
    required: true,
  },
  imageUrl: {
    type: String,
    required: true,
  },
  quantity: {
    type: Number,
    required: true,
  },
  value: {
    type: Number,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
})
defineEmits(['remove'])

const { mobile } = useDisplay()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';
@import '~/assets/styles/mixins.scss';

.item {
  font-weight: bold;
  border: 1px solid $color-secondary;
  border-radius: 4px !important;
  display: flex;
  padding: 6px;
}
.item--mobile {
  padding: 12px;
}
.item__left {
  flex: 0 0 44px;
  border: 1px solid $color-wireframe-1;
  border-radius: 4px;
}
.item__left--mobile {
  flex: 0 0 100px;
}
.item__center {
  flex: 1;
  color: $color-secondary;
  margin: 0 10px;
  display: flex;
  align-items: center;
}
.item__center--mobile {
  flex-direction: column;
  justify-content: space-between;
}
.item__center__top {
  align-self: flex-start;
}
.item__center__bottom {
  align-self: flex-end;
}
.item__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 16px;
}
.item__right__top {
  text-align: right;
}
.item__right__bottom {
  padding-right: 4px;
}
.value {
  display: inline-block;
  width: 35px;
  text-align: right;
}
</style>
