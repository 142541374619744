export default defineNuxtRouteMiddleware((to) => {
  const { isDiscontinued } = useGame2()

  if (!to.name) return

  const routeName = String(to.name)
  if (isDiscontinued.value) {
    if (routeName.startsWith('game-1')) return navigateTo('/')
    if (routeName.startsWith('game-2')) return navigateTo('/')
  } else {
    if (routeName.startsWith('game-2')) return
    if (!routeName.startsWith('game-1')) return
    return navigateTo('/')
  }
})
