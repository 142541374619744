<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 50 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 50.2677C0 51.254 0.807009 52.061 1.79335 52.061H17.6466C18.6329 52.061 19.4399 51.254 19.4399 50.2677V34.3696H30.3615V50.2766C30.3615 51.263 31.1685 52.07 32.1548 52.07H48.0081C48.9944 52.07 49.8014 51.263 49.8014 50.2766V21.5202C49.8014 21.0002 49.5683 20.498 49.1737 20.1573L26.0664 0.430405C25.3939 -0.143468 24.4075 -0.143468 23.735 0.430405L0.627674 20.1573C0.22417 20.498 0 21.0002 0 21.5202V50.2677ZM3.58671 22.3452L24.9007 4.15161L46.2147 22.3452V48.4743H33.9482V32.5763C33.9482 31.5899 33.1412 30.7829 32.1548 30.7829H17.6466C16.6602 30.7829 15.8532 31.5899 15.8532 32.5763V48.4833H3.58671V22.3452Z"
      :fill="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 50
const DEFAULT_HEIGHT = 53

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
