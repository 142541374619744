export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return

  const store = useStoreAuth()
  const localePath = useLocalePath({ route: to })

  if (
    to.meta.layout &&
    to.meta.layout.startsWith('authenticated') &&
    !store.isLogged
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [name, _langage] = String(to.name).split('___')
    if (name === 'shop') {
      return navigateTo({ path: localePath('shop-limited') })
    }
    return navigateTo({ path: localePath('auth'), query: { r: to.fullPath } })
  }
})
