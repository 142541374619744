export default defineNuxtRouteMiddleware((_to, from) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, _langage] = String(from.name).split('___')
  if (name !== 'shop-order-summary') return

  const basket = useStoreBasket()
  if (!basket.hasOrderBeenDone) return

  basket.$reset()
  basket.updateBasketInSession()

  // we've ordered things, lets refresh stars count and shop items (quotas have changed)
  useStoreCard().refreshCard()
  useStoreShop().refreshCatalog()
})
