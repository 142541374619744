<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.1894 10.7039L27.0145 0.10389C26.8516 0.0353224 26.6767 0 26.5 0C26.3233 0 26.1484 0.0353224 25.9855 0.10389L0.810547 10.7039C0.570348 10.8052 0.365346 10.9751 0.221179 11.1923C0.0770119 11.4094 7.84377e-05 11.6643 0 11.925V41.075C3.16594e-05 41.3358 0.0769372 41.5908 0.221102 41.8081C0.365266 42.0254 0.570292 42.1954 0.810547 42.2968L25.9855 52.8968C26.1485 52.9649 26.3234 53 26.5 53C26.6766 53 26.8515 52.9649 27.0145 52.8968L52.1894 42.2968C52.4297 42.1954 52.6347 42.0254 52.7789 41.8081C52.9231 41.5908 53 41.3358 53 41.075V11.925C52.9999 11.6643 52.923 11.4094 52.7788 11.1923C52.6347 10.9751 52.4296 10.8052 52.1894 10.7039ZM26.5 21.0876L16.5756 16.9084L38.2031 7.80232C38.2428 7.78332 38.2815 7.76233 38.319 7.73942L48.2604 11.925L26.5 21.0876ZM26.5 2.76237L34.9221 6.30842L13.3984 15.3714C13.3261 15.4025 13.2568 15.4401 13.1913 15.4838L4.73961 11.925L26.5 2.76237ZM2.65 13.9213L25.175 23.4048V49.6787L2.65 40.1952V13.9213ZM27.825 49.6787V23.4048L50.35 13.9213V40.1952L27.825 49.6787Z"
      :fill="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 53
const DEFAULT_HEIGHT = 53

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
