<template>
  <!-- <v-dialog
    v-model="layout.isMobileNotificationsDrawerOpen"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  > -->
  <v-navigation-drawer
    v-model="layout.isMobileNotificationsDrawerOpen"
    location="bottom"
    permanent
    class="navigation-drawer-notifications bg-primary"
  >
    <v-card
      class="content bg-primary text-secondary rounded-0"
      flat
    >
      <v-toolbar
        color="tertiary"
        density="compact"
      >
        <v-toolbar-title class="font-weight-bold text-secondary">
          Notifications ({{ store.notificationsCount }})
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          variant="text"
          icon="mdi-close"
          @click="layout.closeMobileNotificationsDrawer"
        />
      </v-toolbar>

      <AppNotifications v-if="layout.isMobileNotificationsDrawerOpen" />
    </v-card>
  </v-navigation-drawer>
  <!-- </v-dialog> -->
</template>

<script setup lang="ts">
const layout = useStoreLayout()
const store = useStoreNotifications()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.navigation-drawer-notifications {
  z-index: $z-index-mobile-notifications-drawer !important;
  height: 100% !important;
  border: none;
}
.content {
  padding-bottom: 25vw; // tab-bar height
}
</style>
