<template>
  <AppLink
    :disabled="active"
    :to="to"
    class="profile-tab text-white"
    :class="{
      'profile-tab--desktop': !mobile,
      'profile-tab--active': active,
      'bg-primary': active,
      'bg-secondary': !active,
      'pb-3': hideIcon,
    }"
    :style="{ width }"
  >
    <div>{{ title }}</div>
    <v-icon v-show="!hideIcon">
      {{ active ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
    </v-icon>
  </AppLink>
</template>

<script setup lang="ts">
import type { RouteLocationPathRaw } from 'vue-router'

const props = withDefaults(
  defineProps<{
    title: string
    to: RouteLocationPathRaw
    width?: string
    hideIcon?: boolean
  }>(),
  { width: '220px', hideIcon: false },
)

const route = useRoute()

const active = computed(
  () =>
    route.path.startsWith(props.to.path) &&
    (props.to.hash ? mounted.value && props.to.hash === route.hash : true),
)

const { mobile } = useDisplay()
const { mounted } = useMounted()
</script>

<style lang="scss" scoped>
.profile-tab {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;

  padding-top: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-tab--desktop + .profile-tab--desktop {
  margin-left: 2px;
}
</style>
