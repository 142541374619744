<template>
  <div>
    <AppHeaderRightLogged v-if="store.isLogged" />

    <div v-else>
      <AppButtonRoundTertiary :to="localePath('auth')">
        connexion
      </AppButtonRoundTertiary>
    </div>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const store = useStoreAuth()
</script>
