import { DateTime, Interval } from 'luxon'

export default function (timeAsStr: string) {
  const before = DateTime.fromISO(timeAsStr)
  const now = DateTime.now()
  const interval = Interval.fromDateTimes(before, now)

  const seconds = Math.floor(interval.length('seconds'))
  if (seconds < 60) {
    return `${seconds} sec`
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes < 60) {
    return `${minutes} min`
  }

  const hours = Math.floor(minutes / 60)
  if (hours < 24) {
    return `${hours} h`
  }

  const days = Math.floor(hours / 24)
  if (days < 7) {
    return `${days} jour${days >= 2 ? 's' : ''}`
  }

  const weeks = Math.floor(days / 7)
  if (weeks < 6) {
    return `${weeks} semaine${weeks >= 2 ? 's' : ''}`
  }

  if (days < 366) {
    const months = Math.floor(interval.length('months'))
    return `${months} mois`
  }

  const years = Math.floor(interval.length('years'))
  return `${years} année${years >= 2 ? 's' : ''}`
}
