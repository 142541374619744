const redirects = [
  '/auth',
  '/auth/login',
  '/auth/signup',

  '/profile/addresses',
  '/profile/addresses/new',
  // '/profile/history/proof-of-purchase/[uuid]',
  '/profile/history/declarations',
  '/profile/history/orders',
  '/profile',
  '/profile/infos',
  '/profile/security',

  // '/shop/goodies/[id]/[slug]',
  '/shop/basket',
  '/shop/declaration',
  '/shop',
  '/shop/order-informations',
  '/shop/order-summary',

  '/eligible-products',
  '/how-to-participate',
  '/legal-notices',
  '/reset-password',
  '/shop-conditions',
  '/shop-limited',

  // do not redirect those ones
  // since its the same name in french
  // '/show'
  // '/contact',
  // '/faq',
]

const redirectsToHome = ['/game', '/game/jslots', '/game/your-gift']

export default defineNuxtRouteMiddleware((to) => {
  const localePath = useLocalePath({ route: to })

  if (redirects.includes(to.path)) {
    const routeName = to.path.replace(/^\//, '').replace(/\//g, '-')
    return navigateTo(
      {
        ...to,
        path: localePath(routeName),
      },
      { redirectCode: 301 },
    )
  }

  if (redirectsToHome.includes(to.path)) {
    return navigateTo({ ...to, path: '/' }, { redirectCode: 301 })
  }

  // TODO ? handle those
  // '/profile/history/proof-of-purchase/[uuid]',
  // '/shop/goodies/[id]/[slug]'
})
