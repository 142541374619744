import { DateTime } from 'luxon'

const now = DateTime.now()
const twentySecAgo = now.minus({ seconds: 20 })
const tenMinAgo = now.minus({ minutes: 10 })
const twoDaysAgo = now.minus({ days: 2 })
const threeWeeksAgo = now.minus({ weeks: 3 })
const fourMonthsAgo = now.minus({ months: 4 })
const twoYearsAgo = now.minus({ years: 2 })
export default [
  {
    time: twentySecAgo.toString(),
    text: 'Félicitation ! Votre preuve d’achat #5446 vient d’être validée. Vous venez de gagner 2 étoiles !',
    class: 'text-secondary',
  },
  {
    time: tenMinAgo.toString(),
    text: 'Erreur ! Votre preuve d’achat #0925 n’est pas valide.',
    class: 'text-tertiary',
  },
  {
    time: twoDaysAgo.toString(),
    text: 'Votre preuve d’achat #5446 est en cours de traitement.',
    class: 'text-secondary',
  },
  {
    time: threeWeeksAgo.toString(),
    text: 'Votre commande #3451 est envoyée. Rendez-vous le détail de votre commande dans “Mon historique”, onglet “Mes commandes”.',
    class: 'text-secondary',
  },
  {
    time: fourMonthsAgo.toString(),
    text: 'Votre compte à été mis à jour.',
    class: 'text-secondary',
  },
  {
    time: twoYearsAgo.toString(),
    text: 'Bienvenue sur notre Grande Boutique ! Pour votre première connexion, on vous offre votre une étoile !',
    class: 'text-secondary',
  },
]
