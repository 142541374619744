import { default as indexe1O434uu3yMeta } from "/home/deploy/lustucru-gnocchi/pages/auth/index.vue?macro=true";
import { default as loginIyFjUldrrfMeta } from "/home/deploy/lustucru-gnocchi/pages/auth/login.vue?macro=true";
import { default as signupLxTgp1BgBnMeta } from "/home/deploy/lustucru-gnocchi/pages/auth/signup.vue?macro=true";
import { default as contact6lqSU0Qc0fMeta } from "/home/deploy/lustucru-gnocchi/pages/contact.vue?macro=true";
import { default as eligible_45products2BPQZC5prBMeta } from "/home/deploy/lustucru-gnocchi/pages/eligible-products.vue?macro=true";
import { default as faqsTYEfmIFFSMeta } from "/home/deploy/lustucru-gnocchi/pages/faq.vue?macro=true";
import { default as indexqRL0DAVE6UMeta } from "/home/deploy/lustucru-gnocchi/pages/game-1/index.vue?macro=true";
import { default as jslotsEwmiFSH0M6Meta } from "/home/deploy/lustucru-gnocchi/pages/game-1/jslots.vue?macro=true";
import { default as your_45giftPgS0bslMZOMeta } from "/home/deploy/lustucru-gnocchi/pages/game-1/your-gift.vue?macro=true";
import { default as indexEVXNEQ8lCbMeta } from "/home/deploy/lustucru-gnocchi/pages/game-2/index.vue?macro=true";
import { default as loserRlHAEAKb5vMeta } from "/home/deploy/lustucru-gnocchi/pages/game-2/loser.vue?macro=true";
import { default as resultQVsRqSVDaeMeta } from "/home/deploy/lustucru-gnocchi/pages/game-2/result.vue?macro=true";
import { default as winnerOyb1uAsXqDMeta } from "/home/deploy/lustucru-gnocchi/pages/game-2/winner.vue?macro=true";
import { default as how_45to_45participate02wzpN3Ww2Meta } from "/home/deploy/lustucru-gnocchi/pages/how-to-participate.vue?macro=true";
import { default as indexL436dPluzNMeta } from "/home/deploy/lustucru-gnocchi/pages/index.vue?macro=true";
import { default as legal_45noticesdgNyTGh3OjMeta } from "/home/deploy/lustucru-gnocchi/pages/legal-notices.vue?macro=true";
import { default as indexUDBCt4WML1Meta } from "/home/deploy/lustucru-gnocchi/pages/profile/addresses/index.vue?macro=true";
import { default as newR4c6xkFY9hMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/addresses/new.vue?macro=true";
import { default as declarationsgOIbMzDFdqMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/history/declarations.vue?macro=true";
import { default as ordersZgx3ux2ckwMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/history/orders.vue?macro=true";
import { default as _91uuid_93EqZZ2BdIi5Meta } from "/home/deploy/lustucru-gnocchi/pages/profile/history/proof-of-purchase/[uuid].vue?macro=true";
import { default as index2a5r6Mx9MjMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/index.vue?macro=true";
import { default as infosUYd4Z4IzoWMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/infos.vue?macro=true";
import { default as securityzbeiVoO4qtMeta } from "/home/deploy/lustucru-gnocchi/pages/profile/security.vue?macro=true";
import { default as reset_45passwordSsWRp5tmuWMeta } from "/home/deploy/lustucru-gnocchi/pages/reset-password.vue?macro=true";
import { default as shop_45conditionscZYKNgTVFIMeta } from "/home/deploy/lustucru-gnocchi/pages/shop-conditions.vue?macro=true";
import { default as shop_45limitedKUMItmMe78Meta } from "/home/deploy/lustucru-gnocchi/pages/shop-limited.vue?macro=true";
import { default as basketlg06sVTzQCMeta } from "/home/deploy/lustucru-gnocchi/pages/shop/basket.vue?macro=true";
import { default as declarationnWSfnKXPORMeta } from "/home/deploy/lustucru-gnocchi/pages/shop/declaration.vue?macro=true";
import { default as _91slug_93jKa7aYeaQGMeta } from "/home/deploy/lustucru-gnocchi/pages/shop/goodies/[id]/[slug].vue?macro=true";
import { default as indexPdpWhc0dENMeta } from "/home/deploy/lustucru-gnocchi/pages/shop/index.vue?macro=true";
import { default as order_45informationskzbaMcw5r9Meta } from "/home/deploy/lustucru-gnocchi/pages/shop/order-informations.vue?macro=true";
import { default as order_45summaryv1zSEPXrMJMeta } from "/home/deploy/lustucru-gnocchi/pages/shop/order-summary.vue?macro=true";
import { default as showDhec2xTdrCMeta } from "/home/deploy/lustucru-gnocchi/pages/show.vue?macro=true";
export default [
  {
    name: indexe1O434uu3yMeta?.name ?? "auth___en",
    path: indexe1O434uu3yMeta?.path ?? "/en/auth",
    meta: indexe1O434uu3yMeta || {},
    alias: indexe1O434uu3yMeta?.alias || [],
    redirect: indexe1O434uu3yMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1O434uu3yMeta?.name ?? "auth___fr",
    path: indexe1O434uu3yMeta?.path ?? "/connexion",
    meta: indexe1O434uu3yMeta || {},
    alias: indexe1O434uu3yMeta?.alias || [],
    redirect: indexe1O434uu3yMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: loginIyFjUldrrfMeta?.name ?? "auth-login___en",
    path: loginIyFjUldrrfMeta?.path ?? "/en/auth/login",
    meta: loginIyFjUldrrfMeta || {},
    alias: loginIyFjUldrrfMeta?.alias || [],
    redirect: loginIyFjUldrrfMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: loginIyFjUldrrfMeta?.name ?? "auth-login___fr",
    path: loginIyFjUldrrfMeta?.path ?? "/connexion/se-connecter",
    meta: loginIyFjUldrrfMeta || {},
    alias: loginIyFjUldrrfMeta?.alias || [],
    redirect: loginIyFjUldrrfMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: signupLxTgp1BgBnMeta?.name ?? "auth-signup___en",
    path: signupLxTgp1BgBnMeta?.path ?? "/en/auth/signup",
    meta: signupLxTgp1BgBnMeta || {},
    alias: signupLxTgp1BgBnMeta?.alias || [],
    redirect: signupLxTgp1BgBnMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: signupLxTgp1BgBnMeta?.name ?? "auth-signup___fr",
    path: signupLxTgp1BgBnMeta?.path ?? "/connexion/s-inscrire",
    meta: signupLxTgp1BgBnMeta || {},
    alias: signupLxTgp1BgBnMeta?.alias || [],
    redirect: signupLxTgp1BgBnMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: contact6lqSU0Qc0fMeta?.name ?? "contact___en",
    path: contact6lqSU0Qc0fMeta?.path ?? "/en/contact",
    meta: contact6lqSU0Qc0fMeta || {},
    alias: contact6lqSU0Qc0fMeta?.alias || [],
    redirect: contact6lqSU0Qc0fMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contact6lqSU0Qc0fMeta?.name ?? "contact___fr",
    path: contact6lqSU0Qc0fMeta?.path ?? "/contact",
    meta: contact6lqSU0Qc0fMeta || {},
    alias: contact6lqSU0Qc0fMeta?.alias || [],
    redirect: contact6lqSU0Qc0fMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: eligible_45products2BPQZC5prBMeta?.name ?? "eligible-products___en",
    path: eligible_45products2BPQZC5prBMeta?.path ?? "/en/eligible-products",
    meta: eligible_45products2BPQZC5prBMeta || {},
    alias: eligible_45products2BPQZC5prBMeta?.alias || [],
    redirect: eligible_45products2BPQZC5prBMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/eligible-products.vue").then(m => m.default || m)
  },
  {
    name: eligible_45products2BPQZC5prBMeta?.name ?? "eligible-products___fr",
    path: eligible_45products2BPQZC5prBMeta?.path ?? "/produits-eligibles",
    meta: eligible_45products2BPQZC5prBMeta || {},
    alias: eligible_45products2BPQZC5prBMeta?.alias || [],
    redirect: eligible_45products2BPQZC5prBMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/eligible-products.vue").then(m => m.default || m)
  },
  {
    name: faqsTYEfmIFFSMeta?.name ?? "faq___en",
    path: faqsTYEfmIFFSMeta?.path ?? "/en/faqs",
    meta: faqsTYEfmIFFSMeta || {},
    alias: faqsTYEfmIFFSMeta?.alias || [],
    redirect: faqsTYEfmIFFSMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqsTYEfmIFFSMeta?.name ?? "faq___fr",
    path: faqsTYEfmIFFSMeta?.path ?? "/faq",
    meta: faqsTYEfmIFFSMeta || {},
    alias: faqsTYEfmIFFSMeta?.alias || [],
    redirect: faqsTYEfmIFFSMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexqRL0DAVE6UMeta?.name ?? "game-1___en",
    path: indexqRL0DAVE6UMeta?.path ?? "/en/the-game",
    meta: indexqRL0DAVE6UMeta || {},
    alias: indexqRL0DAVE6UMeta?.alias || [],
    redirect: indexqRL0DAVE6UMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/index.vue").then(m => m.default || m)
  },
  {
    name: indexqRL0DAVE6UMeta?.name ?? "game-1___fr",
    path: indexqRL0DAVE6UMeta?.path ?? "/le-jeu",
    meta: indexqRL0DAVE6UMeta || {},
    alias: indexqRL0DAVE6UMeta?.alias || [],
    redirect: indexqRL0DAVE6UMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/index.vue").then(m => m.default || m)
  },
  {
    name: jslotsEwmiFSH0M6Meta?.name ?? "game-1-jslots___en",
    path: jslotsEwmiFSH0M6Meta?.path ?? "/en/the-game/slot-machine",
    meta: jslotsEwmiFSH0M6Meta || {},
    alias: jslotsEwmiFSH0M6Meta?.alias || [],
    redirect: jslotsEwmiFSH0M6Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/jslots.vue").then(m => m.default || m)
  },
  {
    name: jslotsEwmiFSH0M6Meta?.name ?? "game-1-jslots___fr",
    path: jslotsEwmiFSH0M6Meta?.path ?? "/le-jeu/roulette",
    meta: jslotsEwmiFSH0M6Meta || {},
    alias: jslotsEwmiFSH0M6Meta?.alias || [],
    redirect: jslotsEwmiFSH0M6Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/jslots.vue").then(m => m.default || m)
  },
  {
    name: your_45giftPgS0bslMZOMeta?.name ?? "game-1-your-gift___en",
    path: your_45giftPgS0bslMZOMeta?.path ?? "/en/the-game/your-gift",
    meta: your_45giftPgS0bslMZOMeta || {},
    alias: your_45giftPgS0bslMZOMeta?.alias || [],
    redirect: your_45giftPgS0bslMZOMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/your-gift.vue").then(m => m.default || m)
  },
  {
    name: your_45giftPgS0bslMZOMeta?.name ?? "game-1-your-gift___fr",
    path: your_45giftPgS0bslMZOMeta?.path ?? "/le-jeu/mon-gain",
    meta: your_45giftPgS0bslMZOMeta || {},
    alias: your_45giftPgS0bslMZOMeta?.alias || [],
    redirect: your_45giftPgS0bslMZOMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-1/your-gift.vue").then(m => m.default || m)
  },
  {
    name: indexEVXNEQ8lCbMeta?.name ?? "game-2___en",
    path: indexEVXNEQ8lCbMeta?.path ?? "/en/the-wickedly-greedy-game",
    meta: indexEVXNEQ8lCbMeta || {},
    alias: indexEVXNEQ8lCbMeta?.alias || [],
    redirect: indexEVXNEQ8lCbMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/index.vue").then(m => m.default || m)
  },
  {
    name: indexEVXNEQ8lCbMeta?.name ?? "game-2___fr",
    path: indexEVXNEQ8lCbMeta?.path ?? "/le-jeu-mechamment-gourmand",
    meta: indexEVXNEQ8lCbMeta || {},
    alias: indexEVXNEQ8lCbMeta?.alias || [],
    redirect: indexEVXNEQ8lCbMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/index.vue").then(m => m.default || m)
  },
  {
    name: loserRlHAEAKb5vMeta?.name ?? "game-2-loser___en",
    path: loserRlHAEAKb5vMeta?.path ?? "/en/the-wickedly-greedy-game/lose",
    meta: loserRlHAEAKb5vMeta || {},
    alias: loserRlHAEAKb5vMeta?.alias || [],
    redirect: loserRlHAEAKb5vMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/loser.vue").then(m => m.default || m)
  },
  {
    name: loserRlHAEAKb5vMeta?.name ?? "game-2-loser___fr",
    path: loserRlHAEAKb5vMeta?.path ?? "/le-jeu-mechamment-gourmand/dommage",
    meta: loserRlHAEAKb5vMeta || {},
    alias: loserRlHAEAKb5vMeta?.alias || [],
    redirect: loserRlHAEAKb5vMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/loser.vue").then(m => m.default || m)
  },
  {
    name: resultQVsRqSVDaeMeta?.name ?? "game-2-result___en",
    path: resultQVsRqSVDaeMeta?.path ?? "/en/the-wickedly-greedy-game/result",
    meta: resultQVsRqSVDaeMeta || {},
    alias: resultQVsRqSVDaeMeta?.alias || [],
    redirect: resultQVsRqSVDaeMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/result.vue").then(m => m.default || m)
  },
  {
    name: resultQVsRqSVDaeMeta?.name ?? "game-2-result___fr",
    path: resultQVsRqSVDaeMeta?.path ?? "/le-jeu-mechamment-gourmand/resultat",
    meta: resultQVsRqSVDaeMeta || {},
    alias: resultQVsRqSVDaeMeta?.alias || [],
    redirect: resultQVsRqSVDaeMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/result.vue").then(m => m.default || m)
  },
  {
    name: winnerOyb1uAsXqDMeta?.name ?? "game-2-winner___en",
    path: winnerOyb1uAsXqDMeta?.path ?? "/en/the-wickedly-greedy-game/win",
    meta: winnerOyb1uAsXqDMeta || {},
    alias: winnerOyb1uAsXqDMeta?.alias || [],
    redirect: winnerOyb1uAsXqDMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/winner.vue").then(m => m.default || m)
  },
  {
    name: winnerOyb1uAsXqDMeta?.name ?? "game-2-winner___fr",
    path: winnerOyb1uAsXqDMeta?.path ?? "/le-jeu-mechamment-gourmand/bravo",
    meta: winnerOyb1uAsXqDMeta || {},
    alias: winnerOyb1uAsXqDMeta?.alias || [],
    redirect: winnerOyb1uAsXqDMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/game-2/winner.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45participate02wzpN3Ww2Meta?.name ?? "how-to-participate___en",
    path: how_45to_45participate02wzpN3Ww2Meta?.path ?? "/en/how-to-participate",
    meta: how_45to_45participate02wzpN3Ww2Meta || {},
    alias: how_45to_45participate02wzpN3Ww2Meta?.alias || [],
    redirect: how_45to_45participate02wzpN3Ww2Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/how-to-participate.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45participate02wzpN3Ww2Meta?.name ?? "how-to-participate___fr",
    path: how_45to_45participate02wzpN3Ww2Meta?.path ?? "/comment-participer",
    meta: how_45to_45participate02wzpN3Ww2Meta || {},
    alias: how_45to_45participate02wzpN3Ww2Meta?.alias || [],
    redirect: how_45to_45participate02wzpN3Ww2Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/how-to-participate.vue").then(m => m.default || m)
  },
  {
    name: indexL436dPluzNMeta?.name ?? "index___en",
    path: indexL436dPluzNMeta?.path ?? "/en",
    meta: indexL436dPluzNMeta || {},
    alias: indexL436dPluzNMeta?.alias || [],
    redirect: indexL436dPluzNMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexL436dPluzNMeta?.name ?? "index___fr",
    path: indexL436dPluzNMeta?.path ?? "/",
    meta: indexL436dPluzNMeta || {},
    alias: indexL436dPluzNMeta?.alias || [],
    redirect: indexL436dPluzNMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legal_45noticesdgNyTGh3OjMeta?.name ?? "legal-notices___en",
    path: legal_45noticesdgNyTGh3OjMeta?.path ?? "/en/legal-notices",
    meta: legal_45noticesdgNyTGh3OjMeta || {},
    alias: legal_45noticesdgNyTGh3OjMeta?.alias || [],
    redirect: legal_45noticesdgNyTGh3OjMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/legal-notices.vue").then(m => m.default || m)
  },
  {
    name: legal_45noticesdgNyTGh3OjMeta?.name ?? "legal-notices___fr",
    path: legal_45noticesdgNyTGh3OjMeta?.path ?? "/mentions-legales",
    meta: legal_45noticesdgNyTGh3OjMeta || {},
    alias: legal_45noticesdgNyTGh3OjMeta?.alias || [],
    redirect: legal_45noticesdgNyTGh3OjMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/legal-notices.vue").then(m => m.default || m)
  },
  {
    name: indexUDBCt4WML1Meta?.name ?? "profile-addresses___en",
    path: indexUDBCt4WML1Meta?.path ?? "/en/my-account/my-addresses",
    meta: indexUDBCt4WML1Meta || {},
    alias: indexUDBCt4WML1Meta?.alias || [],
    redirect: indexUDBCt4WML1Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: indexUDBCt4WML1Meta?.name ?? "profile-addresses___fr",
    path: indexUDBCt4WML1Meta?.path ?? "/mon-compte/mes-adresses",
    meta: indexUDBCt4WML1Meta || {},
    alias: indexUDBCt4WML1Meta?.alias || [],
    redirect: indexUDBCt4WML1Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/addresses/index.vue").then(m => m.default || m)
  },
  {
    name: newR4c6xkFY9hMeta?.name ?? "profile-addresses-new___en",
    path: newR4c6xkFY9hMeta?.path ?? "/en/my-account/my-addresses/new-address",
    meta: newR4c6xkFY9hMeta || {},
    alias: newR4c6xkFY9hMeta?.alias || [],
    redirect: newR4c6xkFY9hMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: newR4c6xkFY9hMeta?.name ?? "profile-addresses-new___fr",
    path: newR4c6xkFY9hMeta?.path ?? "/mon-compte/mes-adresses/nouvelle-adresse",
    meta: newR4c6xkFY9hMeta || {},
    alias: newR4c6xkFY9hMeta?.alias || [],
    redirect: newR4c6xkFY9hMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/addresses/new.vue").then(m => m.default || m)
  },
  {
    name: declarationsgOIbMzDFdqMeta?.name ?? "profile-history-declarations___en",
    path: declarationsgOIbMzDFdqMeta?.path ?? "/en/my-account/my-history/my-proofs-of-purchase",
    meta: declarationsgOIbMzDFdqMeta || {},
    alias: declarationsgOIbMzDFdqMeta?.alias || [],
    redirect: declarationsgOIbMzDFdqMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/declarations.vue").then(m => m.default || m)
  },
  {
    name: declarationsgOIbMzDFdqMeta?.name ?? "profile-history-declarations___fr",
    path: declarationsgOIbMzDFdqMeta?.path ?? "/mon-compte/mon-historique/mes-preuves-d-achat",
    meta: declarationsgOIbMzDFdqMeta || {},
    alias: declarationsgOIbMzDFdqMeta?.alias || [],
    redirect: declarationsgOIbMzDFdqMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/declarations.vue").then(m => m.default || m)
  },
  {
    name: ordersZgx3ux2ckwMeta?.name ?? "profile-history-orders___en",
    path: ordersZgx3ux2ckwMeta?.path ?? "/en/my-account/my-history/my-orders",
    meta: ordersZgx3ux2ckwMeta || {},
    alias: ordersZgx3ux2ckwMeta?.alias || [],
    redirect: ordersZgx3ux2ckwMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/orders.vue").then(m => m.default || m)
  },
  {
    name: ordersZgx3ux2ckwMeta?.name ?? "profile-history-orders___fr",
    path: ordersZgx3ux2ckwMeta?.path ?? "/mon-compte/mon-historique/mes-commandes",
    meta: ordersZgx3ux2ckwMeta || {},
    alias: ordersZgx3ux2ckwMeta?.alias || [],
    redirect: ordersZgx3ux2ckwMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/orders.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93EqZZ2BdIi5Meta?.name ?? "profile-history-proof-of-purchase-uuid___en",
    path: _91uuid_93EqZZ2BdIi5Meta?.path ?? "/en/my-account/my-history/my-proof-of-purchase/:uuid",
    meta: _91uuid_93EqZZ2BdIi5Meta || {},
    alias: _91uuid_93EqZZ2BdIi5Meta?.alias || [],
    redirect: _91uuid_93EqZZ2BdIi5Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/proof-of-purchase/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93EqZZ2BdIi5Meta?.name ?? "profile-history-proof-of-purchase-uuid___fr",
    path: _91uuid_93EqZZ2BdIi5Meta?.path ?? "/mon-compte/mon-historique/ma-preuve-d-achat/:uuid",
    meta: _91uuid_93EqZZ2BdIi5Meta || {},
    alias: _91uuid_93EqZZ2BdIi5Meta?.alias || [],
    redirect: _91uuid_93EqZZ2BdIi5Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/history/proof-of-purchase/[uuid].vue").then(m => m.default || m)
  },
  {
    name: index2a5r6Mx9MjMeta?.name ?? "profile___en",
    path: index2a5r6Mx9MjMeta?.path ?? "/en/my-account",
    meta: index2a5r6Mx9MjMeta || {},
    alias: index2a5r6Mx9MjMeta?.alias || [],
    redirect: index2a5r6Mx9MjMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index2a5r6Mx9MjMeta?.name ?? "profile___fr",
    path: index2a5r6Mx9MjMeta?.path ?? "/mon-compte",
    meta: index2a5r6Mx9MjMeta || {},
    alias: index2a5r6Mx9MjMeta?.alias || [],
    redirect: index2a5r6Mx9MjMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: infosUYd4Z4IzoWMeta?.name ?? "profile-infos___en",
    path: infosUYd4Z4IzoWMeta?.path ?? "/en/my-account/my-profile",
    meta: infosUYd4Z4IzoWMeta || {},
    alias: infosUYd4Z4IzoWMeta?.alias || [],
    redirect: infosUYd4Z4IzoWMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/infos.vue").then(m => m.default || m)
  },
  {
    name: infosUYd4Z4IzoWMeta?.name ?? "profile-infos___fr",
    path: infosUYd4Z4IzoWMeta?.path ?? "/mon-compte/mon-profil",
    meta: infosUYd4Z4IzoWMeta || {},
    alias: infosUYd4Z4IzoWMeta?.alias || [],
    redirect: infosUYd4Z4IzoWMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/infos.vue").then(m => m.default || m)
  },
  {
    name: securityzbeiVoO4qtMeta?.name ?? "profile-security___en",
    path: securityzbeiVoO4qtMeta?.path ?? "/en/my-account/connection-and-security",
    meta: securityzbeiVoO4qtMeta || {},
    alias: securityzbeiVoO4qtMeta?.alias || [],
    redirect: securityzbeiVoO4qtMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/security.vue").then(m => m.default || m)
  },
  {
    name: securityzbeiVoO4qtMeta?.name ?? "profile-security___fr",
    path: securityzbeiVoO4qtMeta?.path ?? "/mon-compte/connexion-et-securite",
    meta: securityzbeiVoO4qtMeta || {},
    alias: securityzbeiVoO4qtMeta?.alias || [],
    redirect: securityzbeiVoO4qtMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/profile/security.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordSsWRp5tmuWMeta?.name ?? "reset-password___en",
    path: reset_45passwordSsWRp5tmuWMeta?.path ?? "/en/reset-my-password",
    meta: reset_45passwordSsWRp5tmuWMeta || {},
    alias: reset_45passwordSsWRp5tmuWMeta?.alias || [],
    redirect: reset_45passwordSsWRp5tmuWMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordSsWRp5tmuWMeta?.name ?? "reset-password___fr",
    path: reset_45passwordSsWRp5tmuWMeta?.path ?? "/reinitialiser-son-mot-de-passe",
    meta: reset_45passwordSsWRp5tmuWMeta || {},
    alias: reset_45passwordSsWRp5tmuWMeta?.alias || [],
    redirect: reset_45passwordSsWRp5tmuWMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: shop_45conditionscZYKNgTVFIMeta?.name ?? "shop-conditions___en",
    path: shop_45conditionscZYKNgTVFIMeta?.path ?? "/en/shop-conditions",
    meta: shop_45conditionscZYKNgTVFIMeta || {},
    alias: shop_45conditionscZYKNgTVFIMeta?.alias || [],
    redirect: shop_45conditionscZYKNgTVFIMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop-conditions.vue").then(m => m.default || m)
  },
  {
    name: shop_45conditionscZYKNgTVFIMeta?.name ?? "shop-conditions___fr",
    path: shop_45conditionscZYKNgTVFIMeta?.path ?? "/conditions-de-la-boutique",
    meta: shop_45conditionscZYKNgTVFIMeta || {},
    alias: shop_45conditionscZYKNgTVFIMeta?.alias || [],
    redirect: shop_45conditionscZYKNgTVFIMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop-conditions.vue").then(m => m.default || m)
  },
  {
    name: shop_45limitedKUMItmMe78Meta?.name ?? "shop-limited___en",
    path: shop_45limitedKUMItmMe78Meta?.path ?? "/en/the-big-shop-not-logged",
    meta: shop_45limitedKUMItmMe78Meta || {},
    alias: shop_45limitedKUMItmMe78Meta?.alias || [],
    redirect: shop_45limitedKUMItmMe78Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop-limited.vue").then(m => m.default || m)
  },
  {
    name: shop_45limitedKUMItmMe78Meta?.name ?? "shop-limited___fr",
    path: shop_45limitedKUMItmMe78Meta?.path ?? "/la-grande-boutique-hors-connexion",
    meta: shop_45limitedKUMItmMe78Meta || {},
    alias: shop_45limitedKUMItmMe78Meta?.alias || [],
    redirect: shop_45limitedKUMItmMe78Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop-limited.vue").then(m => m.default || m)
  },
  {
    name: basketlg06sVTzQCMeta?.name ?? "shop-basket___en",
    path: basketlg06sVTzQCMeta?.path ?? "/en/the-big-shop/basket",
    meta: basketlg06sVTzQCMeta || {},
    alias: basketlg06sVTzQCMeta?.alias || [],
    redirect: basketlg06sVTzQCMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/basket.vue").then(m => m.default || m)
  },
  {
    name: basketlg06sVTzQCMeta?.name ?? "shop-basket___fr",
    path: basketlg06sVTzQCMeta?.path ?? "/la-grande-boutique/panier",
    meta: basketlg06sVTzQCMeta || {},
    alias: basketlg06sVTzQCMeta?.alias || [],
    redirect: basketlg06sVTzQCMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/basket.vue").then(m => m.default || m)
  },
  {
    name: declarationnWSfnKXPORMeta?.name ?? "shop-declaration___en",
    path: declarationnWSfnKXPORMeta?.path ?? "/en/the-big-shop/declare-my-purchases",
    meta: declarationnWSfnKXPORMeta || {},
    alias: declarationnWSfnKXPORMeta?.alias || [],
    redirect: declarationnWSfnKXPORMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/declaration.vue").then(m => m.default || m)
  },
  {
    name: declarationnWSfnKXPORMeta?.name ?? "shop-declaration___fr",
    path: declarationnWSfnKXPORMeta?.path ?? "/la-grande-boutique/declarer-mes-achats",
    meta: declarationnWSfnKXPORMeta || {},
    alias: declarationnWSfnKXPORMeta?.alias || [],
    redirect: declarationnWSfnKXPORMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/declaration.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jKa7aYeaQGMeta?.name ?? "shop-goodies-id-slug___en",
    path: _91slug_93jKa7aYeaQGMeta?.path ?? "/en/the-big-shop/gift/:id/:slug",
    meta: _91slug_93jKa7aYeaQGMeta || {},
    alias: _91slug_93jKa7aYeaQGMeta?.alias || [],
    redirect: _91slug_93jKa7aYeaQGMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/goodies/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jKa7aYeaQGMeta?.name ?? "shop-goodies-id-slug___fr",
    path: _91slug_93jKa7aYeaQGMeta?.path ?? "/la-grande-boutique/cadeau/:id/:slug",
    meta: _91slug_93jKa7aYeaQGMeta || {},
    alias: _91slug_93jKa7aYeaQGMeta?.alias || [],
    redirect: _91slug_93jKa7aYeaQGMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/goodies/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPdpWhc0dENMeta?.name ?? "shop___en",
    path: indexPdpWhc0dENMeta?.path ?? "/en/the-big-shop",
    meta: indexPdpWhc0dENMeta || {},
    alias: indexPdpWhc0dENMeta?.alias || [],
    redirect: indexPdpWhc0dENMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indexPdpWhc0dENMeta?.name ?? "shop___fr",
    path: indexPdpWhc0dENMeta?.path ?? "/la-grande-boutique",
    meta: indexPdpWhc0dENMeta || {},
    alias: indexPdpWhc0dENMeta?.alias || [],
    redirect: indexPdpWhc0dENMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: order_45informationskzbaMcw5r9Meta?.name ?? "shop-order-informations___en",
    path: order_45informationskzbaMcw5r9Meta?.path ?? "/en/the-big-shop/order/information",
    meta: order_45informationskzbaMcw5r9Meta || {},
    alias: order_45informationskzbaMcw5r9Meta?.alias || [],
    redirect: order_45informationskzbaMcw5r9Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/order-informations.vue").then(m => m.default || m)
  },
  {
    name: order_45informationskzbaMcw5r9Meta?.name ?? "shop-order-informations___fr",
    path: order_45informationskzbaMcw5r9Meta?.path ?? "/la-grande-boutique/commande/informations",
    meta: order_45informationskzbaMcw5r9Meta || {},
    alias: order_45informationskzbaMcw5r9Meta?.alias || [],
    redirect: order_45informationskzbaMcw5r9Meta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/order-informations.vue").then(m => m.default || m)
  },
  {
    name: order_45summaryv1zSEPXrMJMeta?.name ?? "shop-order-summary___en",
    path: order_45summaryv1zSEPXrMJMeta?.path ?? "/en/the-big-shop/order/summary",
    meta: order_45summaryv1zSEPXrMJMeta || {},
    alias: order_45summaryv1zSEPXrMJMeta?.alias || [],
    redirect: order_45summaryv1zSEPXrMJMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/order-summary.vue").then(m => m.default || m)
  },
  {
    name: order_45summaryv1zSEPXrMJMeta?.name ?? "shop-order-summary___fr",
    path: order_45summaryv1zSEPXrMJMeta?.path ?? "/la-grande-boutique/commande/recapitulatif",
    meta: order_45summaryv1zSEPXrMJMeta || {},
    alias: order_45summaryv1zSEPXrMJMeta?.alias || [],
    redirect: order_45summaryv1zSEPXrMJMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/shop/order-summary.vue").then(m => m.default || m)
  },
  {
    name: showDhec2xTdrCMeta?.name ?? "show___en",
    path: showDhec2xTdrCMeta?.path ?? "/en/show",
    meta: showDhec2xTdrCMeta || {},
    alias: showDhec2xTdrCMeta?.alias || [],
    redirect: showDhec2xTdrCMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/show.vue").then(m => m.default || m)
  },
  {
    name: showDhec2xTdrCMeta?.name ?? "show___fr",
    path: showDhec2xTdrCMeta?.path ?? "/show",
    meta: showDhec2xTdrCMeta || {},
    alias: showDhec2xTdrCMeta?.alias || [],
    redirect: showDhec2xTdrCMeta?.redirect || undefined,
    component: () => import("/home/deploy/lustucru-gnocchi/pages/show.vue").then(m => m.default || m)
  }
]