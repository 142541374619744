import { pick } from 'lodash-es'

export interface IError {
  error: any
  params?: any
  environment?: any
  session?: any
  context?: any
}

export function hydrateError(err: IError) {
  const nuxtApp = useNuxtApp()

  // Object.getOwnPropertyNames(err.error).forEach((key) => {
  //   console.log(`err.error.${key}`, typeof err.error[key], err.error[key])
  // })

  // console.log('hydrateError', err)

  if (!err.context) {
    err.context = {}
  }

  err.context.zState = nuxtApp.$pinia.state.value

  if (err.context.$fetch) {
    if (!err.params) {
      err.params = {}
    }
    err.params.$fetch = {
      request: {
        baseURL: err.error.options.baseURL,
        path: err.error.request,
        method: err.error.options.method,
        body: err.error.options.body,
      },
      response: {
        url: err.error.response && err.error.response.url,
        statusCode: err.error.statusCode,
        statusText: err.error.statusText,
        data: err.error.data,
      },
    }
  }

  if (nuxtApp.$PE && err.error instanceof nuxtApp.$PE.Error) {
    if (!err.params) {
      err.params = {}
    }
    err.params.kitErrorDetails = pick(err.error, [
      'code',
      'invalidReason',
      'invalidParameter',
      'invalidMisc',
    ])
  }

  return err
}
