<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 55 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0195 4.14455C26.0977 2.16756 28.9365 2.16756 30.0146 4.14455L35.2644 13.7706C36.1646 15.4212 37.7591 16.5797 39.607 16.9257L50.3842 18.9439C52.5976 19.3584 53.4749 22.0582 51.9278 23.6946L44.3951 31.662C43.1035 33.0282 42.4945 34.9026 42.7364 36.7671L44.1473 47.6405C44.4371 49.8736 42.1405 51.5422 40.1062 50.5765L30.201 45.8746C28.5025 45.0683 26.5316 45.0683 24.8332 45.8746L14.928 50.5765C12.8937 51.5422 10.5971 49.8736 10.8869 47.6405L12.2978 36.7671C12.5397 34.9026 11.9307 33.0282 10.6391 31.662L3.10637 23.6946C1.55932 22.0582 2.43653 19.3584 4.64994 18.9439L15.4272 16.9257C17.2751 16.5797 18.8696 15.4212 19.7698 13.7706L25.0195 4.14455Z"
      :stroke="colorTextToHex(color)"
      :fill="fill ? colorTextToHex(color) : undefined"
      stroke-width="3.41379"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 55
const DEFAULT_HEIGHT = 53

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
  fill: {
    type: Boolean,
    default: false,
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
