import { markRaw } from 'vue'
import { Notifier } from '@airbrake/browser'

import { hydrateError } from '@/utils/airbrake'
import { ENV_DEV } from '@/utils/env'
import getEnv from '@/utils/runtime/env'
import { getProjectId, getProjectKey } from '@/utils/runtime/airbrake'

export default defineNuxtPlugin(async () => {
  const env = getEnv()
  const nuxtApp = useNuxtApp()

  const airbrake = new Notifier({
    environment: getEnv(),
    projectId: Number(getProjectId()),
    projectKey: getProjectKey(),
  })

  airbrake.addFilter((notice) => {
    if (env !== ENV_DEV) return notice

    console.error(
      'AIRBRAKE : ' + (notice.errors || [notice.error] || [])[0]?.message,
    )
    console.error(notice)
    return null
  })
  airbrake.addFilter((notice) => {
    notice.context.NOTIFIER = 'nuxt.client'
    return notice
  })

  const airbrakeNotify = (err: IError) => airbrake.notify(hydrateError(err))

  nuxtApp.hook('vue:error', (error, instance, info) => {
    airbrakeNotify({ error, context: { instance, info } })
  })

  nuxtApp.$pinia.use(({ store }) => {
    store.airbrakeNotify = markRaw(airbrakeNotify)
  })

  return {
    provide: {
      airbrakeNotify,
    },
  }
})

declare module 'pinia' {
  export interface PiniaCustomProperties {
    airbrakeNotify: Function
  }
}
