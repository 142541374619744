import getPublicRuntimeVariable from '@/utils/functions/getPublicRuntimeVariable'
import getEnv from '@/utils/runtime/env'

export default () => {
  const value = getPublicRuntimeVariable('gtagUas', [
    `LUSTUCRU_GNOCCHI_${getEnv().toUpperCase()}_GTAG_UA`,
    'GTAG_UA',
  ])
  if (!value) return []

  return value.split(',')
}
