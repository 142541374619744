<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 45 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.4476 45.0656L34.1833 15.734C33.4345 12.3829 30.8488 9.62885 27.367 8.10096V7.18936C27.3936 6.36373 27.2544 5.54116 26.9578 4.77055C26.6611 3.99994 26.213 3.29701 25.6402 2.70354C25.0673 2.11007 24.3813 1.63818 23.623 1.31591C22.8647 0.993645 22.0495 0.827576 21.226 0.827576C20.4024 0.827576 19.5873 0.993645 18.829 1.31591C18.0706 1.63818 17.3847 2.11007 16.8118 2.70354C16.2389 3.29701 15.7908 3.99994 15.4942 4.77055C15.1975 5.54116 15.0584 6.36373 15.0849 7.18936V7.95331C11.4112 9.40416 8.62709 12.2352 7.85266 15.7019L0.575574 45.0656C0.517261 45.3021 0.51343 45.5489 0.564372 45.7872C0.615314 46.0255 0.719691 46.249 0.869586 46.4408C1.01948 46.6327 1.21095 46.7877 1.42949 46.8942C1.64802 47.0007 1.88786 47.056 2.13083 47.0557H13.3953C13.6068 48.296 14.1319 49.4613 14.9206 50.44C15.7092 51.4187 16.7349 52.1781 17.8997 52.6457C19.0644 53.1133 20.3293 53.2734 21.5733 53.1106C22.8174 52.9479 23.999 52.4679 25.0053 51.7164C26.5109 50.5835 27.5276 48.9161 27.847 47.0557H39.8923C40.1353 47.056 40.3751 47.0007 40.5937 46.8942C40.8122 46.7877 41.0037 46.6327 41.1536 46.4408C41.3034 46.249 41.4078 46.0255 41.4588 45.7872C41.5097 45.5489 41.5059 45.3021 41.4476 45.0656ZM23.0917 49.1549C22.3787 49.6901 21.5117 49.9785 20.6212 49.9766C19.7995 49.9783 18.9958 49.7351 18.3121 49.278C17.6284 48.8208 17.0955 48.1702 16.781 47.4087H24.4613C24.1719 48.1051 23.6983 48.7089 23.0917 49.1549ZM21.2228 4.21704C21.9926 4.21858 22.7312 4.5225 23.2804 5.06369C23.8295 5.60489 24.1454 6.34029 24.1605 7.11233C22.2277 6.72467 20.2401 6.69855 18.2979 7.03529C18.3276 6.27621 18.6484 5.55796 19.1934 5.03045C19.7384 4.50294 20.4654 4.20693 21.2228 4.20421V4.21704ZM4.17891 43.8587L10.9632 16.4401C11.7952 12.7103 16.0194 10.0204 21.0116 10.0204C26.0038 10.0204 30.2215 12.7296 31.0664 16.4979L37.8442 43.8587H4.17891Z"
      :fill="colorTextToHex(color)"
    />
    <circle
      v-if="active"
      cx="33.0688"
      cy="16.7586"
      r="11.3793"
      :fill="colorTextToHex(activeColor)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 45
const DEFAULT_HEIGHT = 54

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
  active: {
    type: Boolean,
    default: false,
  },
  activeColor: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
