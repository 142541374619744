<template>
  <v-footer
    app
    dark
    color="transparent"
    class="tab-bar pa-0"
  >
    <div class="wrapper-img-star">
      <v-img
        src="~/assets/images/star-with-shadow.png"
        class="align-center"
      >
        <h4
          v-show="auth.isLogged"
          class="wrapper-img-star__text"
        >
          {{ card.availableStarCount }}
        </h4>
      </v-img>
    </div>

    <v-img
      src="~/assets/images/mobile-tab-bar-bg.svg"
      class="align-center"
    >
      <div
        v-if="auth.isLogged"
        class="d-flex justify-space-between pt-5"
      >
        <div class="side side--left">
          <nuxt-link
            to="/"
            class="link"
          >
            <IconHomeSvg
              :height="30"
              color="white"
            />
          </nuxt-link>

          <nuxt-link
            class="link"
            @click.prevent.stop="layout.toggleMobileNotificationsDrawer"
          >
            <IconBellSvg
              :height="30"
              color="white"
              :active="notifications.hasNotifications"
              active-color="tertiary"
            />
          </nuxt-link>
        </div>

        <div class="side side--right">
          <nuxt-link
            :to="localePath('profile')"
            class="link"
          >
            <IconProfileSvg
              :height="30"
              color="white"
            />
          </nuxt-link>

          <nuxt-link
            :to="localePath('shop-basket')"
            class="link"
          >
            <IconBasketSvg
              :height="30"
              color="white"
              class="mr-2"
            />
            {{ basket.purchasesTotalQuantity }}
          </nuxt-link>
        </div>
      </div>
    </v-img>
  </v-footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const auth = useStoreAuth()
const card = useStoreCard()
const basket = useStoreBasket()
const layout = useStoreLayout()
const notifications = useStoreNotifications()

onMounted(() => {
  card.makeSureCardIsLoadedIfLogged()
  basket.makeSureBasketIsLoadedIfLogged()
  notifications.makeSureNotificationsAreLoadedIfLogged()
})
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.tab-bar {
  z-index: $z-index-mobile-tab-bar !important;
  bottom: 0 !important;
}
.wrapper-img-star {
  position: absolute;
  width: 22vw;
  top: -5vw;
  left: 50%;
  transform: translate(-50%);
}
.wrapper-img-star__text {
  padding-top: 2vw;
  text-align: center;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.side {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
}
.side--left {
  padding-right: 10%;
}
.side--right {
  padding-left: 10%;
}
</style>
