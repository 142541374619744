<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 53 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.2321 16.1329C51.8998 15.7041 51.3881 15.4518 50.8448 15.4518H44.4988L36.2412 1.11858C35.7589 0.279821 34.6855 -0.00677393 33.8478 0.475321C33.0093 0.957417 32.7212 2.02882 33.2042 2.86758L40.4545 15.4518H39.4653H39.3076H12.3956L19.6459 2.86828C20.1289 2.02952 19.8408 0.958118 19.0023 0.476023C18.1632 -0.00607228 17.0913 0.280522 16.609 1.11928L8.35129 15.4525H2.00538C1.46277 15.4525 0.950302 15.7041 0.618004 16.1336C0.285706 16.5632 0.172136 17.1223 0.309542 17.6479L7.32004 44.4504C7.52195 45.2212 8.21879 45.7586 9.01588 45.7586H43.8342C44.6313 45.7586 45.3282 45.2205 45.5301 44.4504L52.5406 17.6479C52.6773 17.1216 52.5637 16.5625 52.2321 16.1329ZM39.3076 18.9554H39.4653H48.5755L42.4812 42.2543H10.3696L4.27538 18.9554H39.3076Z"
      :fill="colorTextToHex(color)"
    />
    <path
      d="M52.2321 16.1329C51.8998 15.7041 51.3881 15.4518 50.8448 15.4518H44.4988L36.2412 1.11858C35.7589 0.279821 34.6855 -0.00677393 33.8478 0.475321C33.0093 0.957417 32.7212 2.02882 33.2042 2.86758L40.4545 15.4518H39.4653H39.3076H12.3956L19.6459 2.86828C20.1289 2.02952 19.8408 0.958118 19.0023 0.476023C18.1632 -0.00607228 17.0913 0.280522 16.609 1.11928L8.35129 15.4525H2.00538C1.46277 15.4525 0.950302 15.7041 0.618004 16.1336C0.285706 16.5632 0.172136 17.1223 0.309542 17.6479L7.32004 44.4504C7.52195 45.2212 8.21879 45.7586 9.01588 45.7586H43.8342C44.6313 45.7586 45.3282 45.2205 45.5301 44.4504L52.5406 17.6479C52.6773 17.1216 52.5637 16.5625 52.2321 16.1329ZM39.3076 18.9554H39.4653H48.5755L42.4812 42.2543H10.3696L4.27538 18.9554H39.3076Z"
      :stroke="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 108
const DEFAULT_HEIGHT = 116

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
