<template>
  <v-dialog
    v-model="active"
    width="500"
  >
    <template #default="{ isActive }">
      <v-card class="v-card--lustucru text-center text-secondary">
        <v-icon
          class="close"
          @click="isActive.value = false"
        >
          mdi-close
        </v-icon>
        <v-card-title class="pt-6">
          <AppTitle
            text="Contact"
            :width="150"
            :height="40"
            :y="30"
            :font-size="36"
            :stroke-width="5"
            :shadow-offset="2"
          />
        </v-card-title>
        <v-card-text class="pt-0 px-10">
          Pour nous contacter, envoyez votre message à l’adresse e-mail suivante
          :
        </v-card-text>
        <v-card-actions class="mb-5">
          <div class="flex-grow-1 text-center">
            <h4>
              <!-- eslint-disable vue/html-closing-bracket-newline -->
              <a href="mailto:assistance@20ans-gnocchi.fr"
                >assistance@20ans-gnocchi.fr</a
              >
              <!-- eslint-enable vue/html-closing-bracket-newline -->
            </h4>
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
const layout = useStoreLayout()
const active = computed({
  get() {
    return layout.isContactDialogOpen
  },
  set(value) {
    if (value) {
      layout.openDialogContact()
    } else {
      layout.closeDialogContact()
    }
  },
})
</script>

<style scoped>
.close {
  position: absolute;
  top: 10px;
  right: 30px;
}
</style>
