import getPublicRuntimeVariable from '@/utils/functions/getPublicRuntimeVariable'

export const getProjectId = () =>
  getPublicRuntimeVariable('projectId', [
    'LUSTUCRU_GNOCCHI_AIRBRAKE_PROJECT_ID',
    'AIRBRAKE_PROJECT_ID',
  ])
export const getProjectKey = () =>
  getPublicRuntimeVariable('projectKey', [
    'LUSTUCRU_GNOCCHI_AIRBRAKE_PROJECT_KEY',
    'AIRBRAKE_PROJECT_KEY',
  ])
