import { GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED } from '@/stores/snackbar'
import { executeOnGtagCallback } from '@/utils/functions/gs'

export const TITLE_MISTER = 'Mr'
export const TITLE_MADAM = 'Mme'
export const TITLE_N_A = 'N/A'

export type Title = 'Mr' | 'Mme' | 'N/A'

export interface User {
  id: string
  title: Title | null
  email: string
  firstName: string
  lastName: string
  phone: string | null
  tokens: {
    accessToken: string
    refreshToken: string
  }
}

export const DEFAULT_ROUTE_NAME_AFTER_AUTH = 'profile'

export const useStoreAuth = defineStore('auth', {
  state: () => ({
    isLoading: false,
    user: null as null | User,

    isSettingUpPurcheaseKit: false,
    isPurcheaseKitSetup: false,
    errorOnKitInitiation: false,

    isModalAskToResetPasswordOpen: false,
    isModalResetPasswordMailSentOpen: false,

    isLogingIn: false,
    loginError: null as null | string,

    isSigningUp: false,
    signupError: null as null | string,
    signupWasSuccessful: false,

    isUpdatingUser: false,
    userUpdateError: null as null | string,
    userUpdateWasSuccessful: false,
  }),
  getters: {
    isLogged: (state) => !!state.user,
  },
  actions: {
    openModalAskToResetPassword() {
      this.isModalAskToResetPasswordOpen = true
    },
    openModalResetPasswordMailSent() {
      this.isModalAskToResetPasswordOpen = false
      this.isModalResetPasswordMailSentOpen = true
    },

    async setUser(user: User) {
      this.user = user

      await this.setupPurcheaseKit()
    },
    async setupPurcheaseKit() {
      if (process.server) return
      if (this.isPurcheaseKitSetup) return
      if (this.isSettingUpPurcheaseKit) return

      if (!this.user) {
        throw new Error('trying to setup purchease KIT without user')
      }

      this.isSettingUpPurcheaseKit = true

      try {
        await this.PE.client.authorizeUserWithToken({
          token: this.user.tokens.accessToken,
        })
        this.isPurcheaseKitSetup = true
      } catch (error: any) {
        const snackbar = useStoreSnackbar()
        if (
          error instanceof this.PE.Error &&
          error.code === this.PE.Error.Codes.UNAUTHORIZED
        ) {
          this.logout()
          snackbar.addToast({
            text: 'Vos identifiants ne sont plus valide, merci de vous connecter à nouveau.',
          })
          return
        }

        this.errorOnKitInitiation = true
        this.airbrakeNotify({
          error,
          context: { locator: 'unknown error on kit initiation' },
        })
        return
      } finally {
        this.isSettingUpPurcheaseKit = false
      }

      this.errorOnKitInitiation = false
    },

    async signup({
      email,
      password,
      lastName,
      firstName,
    }: {
      email: string
      password: string
      lastName: string
      firstName: string
    }) {
      if (this.isSigningUp) return

      this.isSigningUp = true
      this.signupError = null

      const callbackToExecute = () =>
        $fetch('/api/signup', {
          method: 'POST',
          body: {
            email,
            password,
            lastName,
            firstName,
          },
        })
          .then(
            async (result) => {
              await this.setUser(result.user)
              this.signupWasSuccessful = true
            },
            (error) => {
              if (!error.data) {
                this.signupError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
                this.airbrakeNotify({
                  error,
                  context: {
                    fetch: true,
                    locator: 'signup - unknown error - no data',
                  },
                })
                return
              }

              if (error.data.statusCode !== 400) {
                this.signupError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
                this.airbrakeNotify({
                  error,
                  context: { fetch: true, locator: 'signup - unknown error' },
                })
                return
              }

              switch (error.data.data.error) {
                case 'account_already_exists':
                  this.signupError = error.data.data.user_message
                  break
                default:
                  this.signupError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
                  this.airbrakeNotify({
                    error,
                    context: {
                      fetch: true,
                      locator: 'signup - 400 - unknown error code',
                    },
                  })
              }
            },
          )
          .finally(() => {
            this.isSigningUp = false
          })

      executeOnGtagCallback(callbackToExecute)
    },

    async login({ email, password }: { email: string; password: string }) {
      if (this.isLogingIn) return

      this.isLogingIn = true
      this.loginError = null

      $fetch('/api/login', {
        method: 'POST',
        body: {
          email,
          password,
        },
      })
        .then(
          async (result) => {
            await this.setUser(result.user)
            this.redirectAfterAuth()
          },
          (error) => {
            if (!error.data) {
              this.loginError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
              this.airbrakeNotify({
                error,
                context: {
                  $fetch: true,
                  locator: 'login - unknown error - no data',
                },
              })
              return
            }

            if (error.data.statusCode !== 400) {
              this.loginError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
              this.airbrakeNotify({
                error,
                context: { $fetch: true, locator: 'login - unknown error' },
              })
              return
            }

            switch (error.data.data.error) {
              case 'unknown_email_or_password':
                this.loginError = error.data.data.user_message
                break
              default:
                this.loginError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
                this.airbrakeNotify({
                  error,
                  context: {
                    $fetch: true,
                    locator: 'login - 400 - unknown error code',
                  },
                })
            }
          },
        )
        .finally(() => {
          this.isLogingIn = false
        })
    },

    async logout() {
      try {
        await $fetch('/api/logout', { method: 'DELETE' })
      } catch (error) {
        this.airbrakeNotify({
          error,
          context: {
            $fetch: true,
            locator: '/api/logout',
          },
        })
        this.refreshPageAfterDisplayToast()
      }
      try {
        await this.PE.client.logout()
      } catch (error) {
        this.airbrakeNotify({
          error,
          context: {
            locator: 'PE.client.logout',
          },
        })
        this.refreshPageAfterDisplayToast()
      }

      useStoreBasket().$reset()
      useStoreCard().$reset()
      useStoreGame1().$reset()
      useStoreGame2().$reset()
      useStoreLayout().$reset()
      useStoreNotifications().$reset()
      useStoreShop().$reset()
      this.$reset()

      useRouter().push('/')
    },

    redirectAfterAuth() {
      const router = useRouter()
      const localePath = useLocalePath()
      const redirect = router.currentRoute.value.query.r
        ? String(router.currentRoute.value.query.r)
        : localePath(DEFAULT_ROUTE_NAME_AFTER_AUTH)
      router.push(redirect)
    },

    updateUser(data: any) {
      if (this.isUpdatingUser) return

      this.isUpdatingUser = true
      this.userUpdateError = null

      $fetch('/api/updateUser', {
        method: 'PUT',
        body: data,
      })
        .then(
          async (result: any) => {
            await this.setUser(result.user)
            this.userUpdateWasSuccessful = true
          },
          (error) => {
            if (!error.data) {
              this.userUpdateError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
              this.airbrakeNotify({
                error,
                context: {
                  $fetch: true,
                  locator: 'updateUser - unknown error - no data',
                },
              })
              return
            }

            if (error.data.statusCode !== 400) {
              this.userUpdateError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
              this.airbrakeNotify({
                error,
                context: {
                  $fetch: true,
                  locator: 'updateUser - unknown error',
                },
              })
              return
            }

            switch (error.data.data.error) {
              case 'unknown_email_or_password':
                this.userUpdateError = error.data.data.user_message
                break
              default:
                this.userUpdateError = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
                this.airbrakeNotify({
                  error,
                  context: {
                    $fetch: true,
                    locator: 'updateUser - 400 - unknown error code',
                  },
                })
            }
          },
        )
        .finally(() => {
          this.isUpdatingUser = false
        })
    },

    refreshPageAfterDisplayToast() {
      const timeout = 5_000
      const snackbar = useStoreSnackbar()
      snackbar.addToast({
        text: 'Une erreur critique est survenue, nous allons recharger la page.',
        timeout,
      })
      setTimeout(() => {
        location.reload()
      }, timeout)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreAuth, import.meta.hot))
}
