<template>
  <v-card class="text-secondary">
    <v-toolbar
      v-if="auth.user"
      color="tertiary"
      density="compact"
    >
      <v-toolbar-title class="font-weight-bold text-secondary">
        {{ auth.user.firstName }}
        {{ auth.user.lastName }}
      </v-toolbar-title>
    </v-toolbar>

    <v-list>
      <v-list-item
        :to="localePath('profile')"
        class="text-secondary"
      >
        <template #prepend>
          <IconProfileSvg
            color="secondary"
            :width="20"
            class="mr-3"
          />
        </template>
        <v-list-item-title class="font-weight-bold">
          Mon compte
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :to="localePath('profile-history-declarations')"
        class="text-secondary"
      >
        <template #prepend>
          <IconReceiptSvg
            color="secondary"
            :width="20"
            class="mr-3"
          />
        </template>
        <v-list-item-title class="font-weight-bold">
          Mes preuves d'achat
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        :to="localePath('profile-history-orders')"
        class="text-secondary"
      >
        <template #prepend>
          <IconBoxSvg
            color="secondary"
            :width="20"
            class="mr-3"
          />
        </template>
        <v-list-item-title class="font-weight-bold">
          Mes commandes
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        class="text-tertiary"
        @click="auth.logout"
      >
        <template #prepend>
          <IconPowerSvg
            color="tertiary"
            :width="20"
            class="mr-3"
          />
        </template>
        <v-list-item-title class="font-weight-bold">
          Déconnexion
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const auth = useStoreAuth()
</script>
