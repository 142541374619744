import { markRaw } from 'vue'
import type { PiniaPluginContext } from 'pinia'

import { Error, client } from '@purchease/sdk'

import getClientId from '@/utils/runtime/client-id'
import getEnv from '@/utils/runtime/env'

const PE = { Error, client }

async function configureClient(client: any, store: any) {
  const isAuthorized = await client.isAuthorized()
  if (!isAuthorized && store.isLogged) {
    await store.setupPurcheaseKit()
  }
}

// executed on each store
function MyPiniaPlugin({ store }: PiniaPluginContext) {
  store.PE = markRaw(PE)
  if (store.$id === 'auth') {
    configureClient(client, store)
  }
}

export default defineNuxtPlugin(async () => {
  await client.configure({ environment: getEnv(), region: 'fr' })

  if (!client.isInitialized()) {
    await client.init(getClientId(), ' ', ' ')
  }

  const { $pinia } = useNuxtApp()
  $pinia.use(MyPiniaPlugin)
  return {
    provide: {
      PE,
    },
  }
})

declare module 'pinia' {
  export interface PiniaCustomProperties {
    PE: any
  }
}
