<template>
  <div class="fill-height pos-relative bg-primary">
    <div class="wrapper-background-image">
      <img
        class="background-image"
        src="~/assets/images/landing-pages/tf2/confettis-shop.png"
      />
    </div>

    <img
      class="pot-holder-blue"
      src="~/assets/images/landing-pages/pot-holder-blue.png"
    />
    <img
      class="pot-holder-red"
      src="~/assets/images/landing-pages/pot-holder-red.png"
    />

    <div class="content font-weight-500 fill-height">
      <div class="wrapper">
        <div class="outlined--white mb-5 bg-primary text-white">
          Jusqu'au 31/12/2024
        </div>

        <div class="mt-5">
          <AppTitle
            text="La Grande"
            :width="510"
            :height="90"
            :y="74"
            :font-size="90"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>
        <div class="mb-3">
          <AppTitle
            text="Boutique"
            :width="460"
            :height="90"
            :y="70"
            :font-size="90"
            :stroke-width="6"
            :shadow-offset="3"
            text-uppercase
          />
        </div>

        <div class="font-size-24px">
          Transformez vos achats en objets collector !
        </div>

        <div class="font-size-18px">
          Valable sur les produits Lustucru Sélection au rayon frais.
        </div>
        <div class="font-size-18px mb-5">
          <nuxt-link
            :to="{
              path: localePath('eligible-products'),
              hash: t(URL_HASH_FOR_SHOP),
            }"
          >
            Voir la liste des produits éligibles
          </nuxt-link>
        </div>
        <div class="mb-10">
          <AppButtonTertiary
            :to="localePath('shop')"
            width="240px"
          >
            La Grande Boutique
          </AppButtonTertiary>
        </div>

        <div class="wrapper-img mb-10">
          <v-img src="~/assets/images/landing-pages/tf2/goodies@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.pos-relative {
  position: relative;
}
.wrapper-background-image {
  z-index: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.background-image {
  position: absolute;
  height: 100%;
  bottom: 0;
  top: 0;
  right: 0;
}
.pot-holder-blue {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40%;
  z-index: 0;
}
.pot-holder-red {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  z-index: 0;
}

.content {
  position: relative;
  z-index: 10;

  display: flex;
  align-items: center;
}

.wrapper {
  width: 100%;
  padding-top: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlined--white {
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  padding: 0 5px;
}
.wrapper-img {
  width: 50%;
  position: relative;
  left: 2%;
}
</style>
