const secondary = '#20206C'
const white = '#FFFFFF'

export const myCustomLightTheme = {
  dark: false,
  colors: {
    // background: '#FFFFFF',
    // surface: '#FFFFFF',
    'on-surface': secondary,
    // 'surface-bright': '#FFFFFF',
    // 'surface-light': '#EEEEEE',
    // 'surface-variant': '#424242',
    // 'on-surface-variant': '#EEEEEE',

    primary: '#12BAEE', // light blue
    'on-primary': secondary,
    secondary, // dark blue
    'on-secondary': white,

    error: '#FF0000',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',

    tertiary: '#FEC337', // yellow
    'on-tertiary': secondary,
    quaternary: '#F16D04', // orange
    'on-quaternary': secondary,

    'wireframe-1': '#D9D9D9',
    'wireframe-2': '#777777',
    'wireframe-3': '#EFEFEF',
    'wireframe-4': '#F2F2F2',
    'wireframe-5': '#F4F4F4',

    white: '#FFFFFF',

    'minions-blue-dark': '#1b9dd9',
    'minions-blue-light': '#99d9f5',
    'on-minions-blue-light': secondary,
    'minions-yellow': '#ffdd00',
    'on-minions-yellow': secondary,
    'minions-rose': '#ed1185',
  },
}
