<template>
  <v-card class="basket bg-white text-secondary">
    <v-toolbar
      color="tertiary"
      density="compact"
    >
      <v-toolbar-title class="font-weight-bold text-secondary">
        Votre panier ({{ basket.purchasesTotalQuantity }})
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text class="basket__content">
      <AppHeaderRightMenuBasketEmpty
        v-if="basket.isEmpty"
        @close="$emit('close')"
      />
      <AppHeaderRightMenuBasketNotEmpty
        v-else
        @close="$emit('close')"
      />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
const basket = useStoreBasket()
defineEmits(['close'])
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.basket {
  width: 300px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.basket__content {
  max-height: calc(100vh - $height-app-bar - $height-notif-center-header);
  overflow: auto;
}
.purchases {
  list-style: none;
  font-size: 16px;
  line-height: 20px;
}
.purchase + .purchase {
  margin-top: 16px;
}

.basket__recap {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $color-secondary;
  margin: 20px 0;
}
</style>
