<template>
  <div class="profile-tabs">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.profile-tabs {
  display: flex;
}
</style>
