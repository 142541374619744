<template>
  <!--
    can be improve with a queue system
    https://github.com/ajanes93/vuetify-snackbar-queue/blob/master/src/components/VSnackbarQueue.vue
    /!\ do not copy it, it uses "underscore" and there is a problem with timeouts
    https://github.com/ajanes93/vuetify-snackbar-queue/issues/3
    we can copy it and improve it
  -->
  <v-snackbar
    v-model="active"
    :color="options.color"
    :multi-line="options.mode === 'multi-line'"
    :timeout="options.timeout"
    :vertical="options.mode === 'vertical'"
  >
    {{ options.text }}
    <template #actions>
      <v-btn @click="active = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
const snackbar = useStoreSnackbar()
const { active, options } = storeToRefs(snackbar)
watch(active, (value) => {
  if (value) return

  snackbar.$reset()
})
</script>
