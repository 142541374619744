import { GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED } from '@/stores/snackbar'

export interface Card {
  name: string
  data: {
    available_points: number
  }
}

export const useStoreCard = defineStore('card', {
  state: () => ({
    isLoading: false,
    fetchErrorText: null as null | string,
    card: null as null | Card,
  }),
  getters: {
    availableStarCount(state) {
      return state.card && state.card.data.available_points
    },
  },
  actions: {
    async fetchCard() {
      this.isLoading = true
      this.fetchErrorText = null
      let card: Card
      try {
        card = await this.PE.client.getCard()
      } catch (error) {
        this.fetchErrorText = GENERIC_ERROR_TEXT_THAT_WILL_BE_CORRECTED
        useNuxtApp().$airbrakeNotify({
          error,
          context: { locator: 'PE.client.getCard' },
        })
        return
      } finally {
        this.isLoading = false
      }

      this.card = card
      return card
    },
    async refreshCard() {
      return this.fetchCard()
    },
    async makeSureCardIsLoaded() {
      if (this.card) return
      if (this.isLoading) return

      return this.fetchCard()
    },
    async makeSureCardIsLoadedIfLogged() {
      const auth = useStoreAuth()
      if (!auth.isLogged) return

      return this.makeSureCardIsLoaded()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreCard, import.meta.hot))
}
