// import mockedData from '@/utils/mocked-data/shop'
// const mocked = false

export interface Catalog {
  owner_type: string
  loyalty_card: {
    name: string
  }
  coupons: Coupon[]
}

export interface Coupon {
  identifier: string

  name: string
  tag_line: string
  description: string

  value: number

  image_url: string
  image_1x_url: null | string
  image_2x_url: null | string
  image_3x_url: null | string
  background_url: string

  leftovers: number
  is_soon_depleted: boolean
}

export const useStoreShop = defineStore('shop', {
  state: () => ({
    isFetchingCatalog: false,
    catalog: null as null | Catalog,
    selectedGoodyIdentifier: null as null | string,
  }),
  getters: {
    columnsToAddCount: (state) => {
      if (!state.catalog) return 0

      switch (state.catalog.coupons.length % 4) {
        case 0:
          return 0
        case 1:
          return 3
        case 2:
          return 2
        case 3:
          return 1
      }
    },
    getGoodyByIdentifier(state) {
      return (identifier: string) =>
        state.catalog?.coupons.find((c) => c.identifier === identifier)
    },
    selectedGoody(state): undefined | Coupon {
      if (!state.selectedGoodyIdentifier) return
      if (!state.catalog) return
      return state.catalog.coupons.find(
        (c) => c.identifier === state.selectedGoodyIdentifier,
      )
    },
  },
  actions: {
    findGoody(identifier: string) {
      if (!this.catalog) return

      return this.catalog.coupons.find((c) => c.identifier === identifier)
    },
    async fetchCatalog() {
      if (this.isFetchingCatalog) return

      return this.refreshCatalog()
    },
    async refreshCatalog() {
      this.isFetchingCatalog = true
      let catalog: Catalog
      try {
        catalog = await this.PE.client.getCatalog()
      } finally {
        this.isFetchingCatalog = false
      }

      this.catalog = catalog
      // if (mocked) {
      //   this.catalog.coupons = mockedData
      // }

      return catalog
    },
    selectGoody(identifier: string) {
      this.selectedGoodyIdentifier = identifier
    },
    async fetchGoody(identifier: string) {
      this.selectGoody(identifier)
      return this.makeSureShopIsLoaded()
    },

    async makeSureShopIsLoaded() {
      if (this.catalog) return

      return this.fetchCatalog()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreShop, import.meta.hot))
}
