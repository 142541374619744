interface Breadcrumb {
  text: string
  to?: string
}

export default function () {
  const route = useRoute()
  const localePath = useLocalePath()
  const shop = useStoreShop()

  const breadcrumbs = computed(() => {
    let list: Breadcrumb[]

    if (!route.name) {
      throw new Error('route.name is undefined')
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [name, _langage] = String(route.name).split('___')

    switch (name) {
      case 'game-1':
        list = [{ text: 'Le Jeu' }]
        break
      case 'game-1-jslots':
        list = [
          { text: 'Le Jeu', to: localePath('game-1') },
          { text: 'Tirage au sort' },
        ]
        break
      case 'game-1-your-gift':
        list = [
          { text: 'Le Jeu', to: localePath('game-1') },
          { text: 'Votre Cadeau' },
        ]
        break

      case 'game-2':
        list = [{ text: 'Le Jeu' }]
        break
      case 'game-2-result':
        list = [
          { text: 'Le Jeu', to: localePath('game-2') },
          { text: 'Tirage au sort' },
        ]
        break
      case 'game-2-winner':
        list = [
          { text: 'Le Jeu', to: localePath('game-2') },
          { text: 'Votre Cadeau' },
        ]
        break
      case 'game-2-loser':
        list = [
          { text: 'Le Jeu', to: localePath('game-2') },
          { text: 'Votre Cadeau' },
        ]
        break

      case 'profile-addresses':
        list = [{ text: 'Mon compte' }]
        break

      case 'profile-addresses-new':
        list = [{ text: 'Mon compte' }]
        break

      case 'profile-history-proof-of-purchase-uuid':
        list = [
          {
            text: 'Mon historique',
            to: localePath('profile-history-declarations'),
          },
          { text: "Mes preuves d'achats" },
        ]
        break

      case 'profile-history-declarations':
        list = [{ text: 'Mon historique' }]
        break

      case 'profile-history-orders':
        list = [{ text: 'Mon historique' }]
        break

      case 'profile':
        list = [{ text: 'Mon compte' }]
        break

      case 'profile-infos':
        list = [{ text: 'Mon compte' }]
        break

      case 'profile-security':
        list = [{ text: 'Mon compte' }]
        break

      case 'shop-goodies-id-slug':
        list = [{ text: 'La Grande Boutique', to: localePath('shop') }]
        if (shop.selectedGoody) {
          list.push({ text: shop.selectedGoody.name })
        }
        break

      case 'shop-basket':
        list = [
          { text: 'La Grande Boutique', to: localePath('shop') },
          { text: 'Panier' },
        ]
        break

      case 'shop-declaration':
        list = [
          { text: 'La Grande Boutique', to: localePath('shop') },
          { text: 'Déclarer mes achats' },
        ]
        break

      case 'shop':
        list = [{ text: 'La Grande Boutique' }]
        break

      case 'shop-order-informations':
        list = [
          { text: 'La Grande Boutique', to: localePath('shop') },
          { text: 'Panier', to: localePath('shop-basket') },
          { text: 'Commander' },
        ]
        break

      case 'shop-order-summary':
        list = [
          { text: 'La Grande Boutique', to: localePath('shop') },
          { text: 'Panier', to: localePath('shop-basket') },
          { text: 'Commander' },
        ]
        break

      case 'eligible-products':
        list = [{ text: 'Produits éligibles' }]
        break

      case 'faq':
        list = [{ text: 'Aide et FAQ' }]
        break

      case 'how-to-participate':
        list = [{ text: 'Comment participer ?' }]
        break

      case 'legal-notices':
        list = [{ text: 'Mentions Légales' }]
        break

      case 'shop-conditions':
        list = [{ text: 'Conditions de la Boutique' }]
        break

      case 'shop-limited':
        list = [{ text: 'La Grande Boutique' }]
        break

      default:
        list = []
    }

    if (list.length === 0) return list

    const baseLink: Breadcrumb = { text: 'Accueil', to: '/' }
    return [baseLink].concat(list)
  })

  return { breadcrumbs }
}
