<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 38 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2010_5981)">
      <path
        d="M19 19.9033C19.8343 19.9033 20.5156 19.222 20.5156 18.3824V1.52088C20.5156 0.681319 19.8343 0 19 0C18.1658 0 17.4844 0.681319 17.4844 1.52088V18.378C17.4844 19.2176 18.1614 19.8989 19 19.8989V19.9033Z"
        :fill="colorTextToHex(color)"
      />
      <path
        d="M30.7451 6.17143C30.0899 5.65715 29.1377 5.77143 28.6223 6.42198C28.3733 6.73846 28.2598 7.13407 28.3078 7.53407C28.3559 7.93407 28.5568 8.29451 28.8756 8.54506C32.7499 11.5868 34.9731 16.1451 34.9731 21.0593C34.9731 29.8374 27.8099 36.9758 19 36.9758C10.1901 36.9758 3.0269 29.8374 3.0269 21.0593C3.0269 16.1363 5.25885 11.5692 9.15058 8.52308C9.46943 8.27253 9.67035 7.91649 9.71839 7.51209C9.76644 7.11209 9.65724 6.71649 9.40828 6.4C8.89287 5.74506 7.94069 5.63077 7.28552 6.14506C2.65563 9.76704 0 15.2 0 21.0593C0 31.5033 8.52161 40 19 40C29.4784 40 38 31.5033 38 21.0593C38 15.2176 35.3575 9.78901 30.7451 6.17143Z"
        :fill="colorTextToHex(color)"
      />
    </g>
    <defs>
      <clipPath id="clip0_2010_5981">
        <rect
          width="38"
          height="40"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 38
const DEFAULT_HEIGHT = 40

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
