<template>
  <svg
    :width="computedWidth"
    :height="computedHeight"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.4069 50.7111H25.5935C19.9113 50.7111 15.2891 46.0889 15.2891 40.4067V25.5933C15.2891 19.9111 19.9113 15.2889 25.5935 15.2889H40.4069C46.0891 15.2889 50.7113 19.9111 50.7113 25.5933V40.4067C50.7113 46.0889 46.0891 50.7111 40.4069 50.7111ZM25.5935 18.3841C21.6195 18.3841 18.3843 21.6172 18.3843 25.5933V40.4067C18.3843 44.3807 21.6174 47.6159 25.5935 47.6159H40.4069C44.3809 47.6159 47.6161 44.3828 47.6161 40.4067V25.5933C47.6161 21.6193 44.383 18.3841 40.4069 18.3841H25.5935Z"
      :fill="colorTextToHex(color)"
    />
    <path
      d="M33.0001 42.0572C28.0054 42.0572 23.9429 37.9948 23.9429 33C23.9429 28.0052 28.0054 23.9427 33.0001 23.9427C37.9949 23.9427 42.0574 28.0052 42.0574 33C42.0574 37.9948 37.9949 42.0572 33.0001 42.0572ZM33.0001 27.038C29.7135 27.038 27.0381 29.7134 27.0381 33C27.0381 36.2866 29.7135 38.962 33.0001 38.962C36.2867 38.962 38.9621 36.2866 38.9621 33C38.9621 29.7134 36.2867 27.038 33.0001 27.038Z"
      :fill="colorTextToHex(color)"
    />
    <path
      d="M42.4651 25.7085C43.6585 25.7085 44.626 24.741 44.626 23.5476C44.626 22.3542 43.6585 21.3867 42.4651 21.3867C41.2717 21.3867 40.3042 22.3542 40.3042 23.5476C40.3042 24.741 41.2717 25.7085 42.4651 25.7085Z"
      :fill="colorTextToHex(color)"
    />
    <path
      d="M33 66C14.8032 66 0 51.1968 0 33C0 14.8032 14.8032 0 33 0C51.1968 0 66 14.8032 66 33C66 51.1968 51.1968 66 33 66ZM33 2.96352C16.4372 2.96352 2.96352 16.4372 2.96352 33C2.96352 49.5628 16.4372 63.0365 33 63.0365C49.5628 63.0365 63.0365 49.5628 63.0365 33C63.0365 16.4372 49.5628 2.96352 33 2.96352Z"
      :fill="colorTextToHex(color)"
    />
  </svg>
</template>

<script setup lang="ts">
import colorTextToHex from '@/utils/functions/colorTextToHex'

const DEFAULT_WIDTH = 66
const DEFAULT_HEIGHT = 66

const props = defineProps({
  width: {
    type: Number,
    default: null,
  },
  height: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: 'white',
  },
})

function computeWidth(height: number) {
  return (height * DEFAULT_WIDTH) / DEFAULT_HEIGHT
}
function computeHeight(width: number) {
  return (width * DEFAULT_HEIGHT) / DEFAULT_WIDTH
}

const computedWidth = computed(() =>
  props.width ? props.width : computeWidth(props.height),
)
const computedHeight = computed(() =>
  props.height ? props.height : computeHeight(props.width),
)
</script>
