import { en, fr } from 'vuetify/locale'

import { URL_HASH_FOR_GAME, URL_HASH_FOR_SHOP } from '@/utils/constants'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'fr',
  messages: {
    en: {
      $vuetify: en,
      [URL_HASH_FOR_GAME]: '#the-game',
      [URL_HASH_FOR_SHOP]: '#the-big-shop',
    },
    fr: {
      $vuetify: fr,
      [URL_HASH_FOR_GAME]: '#le-jeu',
      [URL_HASH_FOR_SHOP]: '#la-grande-boutique',
    },
  },
}))
