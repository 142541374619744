import { find } from 'lodash-es'

import { GENERIC_ERROR_TEXT } from '@/stores/snackbar'
import type { IAddress } from '@/utils/types/core/address'

interface IAttachUserInfoArguments {
  email: string
  firstName: string
  postalCode: string
  city: string
  lastName: string
  address1: string
  address2: string
  title: string
  phoneNumber: string
  termsAccepted: boolean
}

export const useStoreGame1 = defineStore('game1', () => {
  const nuxtApp = useNuxtApp()

  const isUnfinished = ref(false)
  const code = ref('')
  const codeId = ref(0)
  const proofOfPurchaseNeeded = ref(false)
  const s3UploadImagePath = ref('')
  const benefit = ref({})
  const win = ref([1, 1, 1, 1])
  const isParticipationComplete = ref(false)

  const email = ref('')
  const firstName = ref('')
  const postalCode = ref('')
  const city = ref('')
  const lastName = ref('')
  const address1 = ref('')
  const address2 = ref('')
  const title = ref('')
  const phoneNumber = ref('')
  const termsAccepted = ref(false)

  const errorMessage = ref('')
  const stepValue = ref(1)
  const imageUrl = ref('')
  const receipts = ref([] as File[][])
  const filesCodePicture = ref([] as File[])
  const receiptKitUuid = ref('')
  const productShotImageKey = ref('')
  const isModalOpenProofOfPurchase = ref(false)
  const codeIsLoaded = ref(false)
  const keyForDisplay = ref('')

  function $reset() {
    isUnfinished.value = false
    code.value = ''
    codeId.value = 0
    proofOfPurchaseNeeded.value = false
    s3UploadImagePath.value = ''
    benefit.value = {}
    win.value = [1, 1, 1, 1]
    isParticipationComplete.value = false

    email.value = ''
    firstName.value = ''
    postalCode.value = ''
    city.value = ''
    lastName.value = ''
    address1.value = ''
    address2.value = ''
    title.value = ''
    phoneNumber.value = ''
    termsAccepted.value = false

    errorMessage.value = ''
    stepValue.value = 1
    imageUrl.value = ''
    receipts.value = []
    filesCodePicture.value = []
    receiptKitUuid.value = ''
    productShotImageKey.value = ''
    isModalOpenProofOfPurchase.value = false
    codeIsLoaded.value = false
    keyForDisplay.value = ''
  }

  function setCode(codeInputValue: string) {
    code.value = codeInputValue.toUpperCase()
  }
  const burnCode = async () => {
    errorMessage.value = ''

    const router = useRouter()
    const localePath = useLocalePath()

    let response
    try {
      response = await nuxtApp.$PE.client.burnUniqCode({
        code: code.value,
      })
    } catch (error) {
      nuxtApp.$airbrakeNotify({
        error,
        context: { locator: 'burnCode #1' },
      })
      errorMessage.value = GENERIC_ERROR_TEXT
      return
    }

    if (response.error) {
      switch (response.error.code) {
        case 'not_existing':
          errorMessage.value = 'Code invalide'
          break
        case 'not_accessible':
          errorMessage.value = 'Code non valide'
          break
        case 'already_burned':
          errorMessage.value = 'Code déjà utilisé'
          break
        case 'empty':
          errorMessage.value = "Aucun code n'a été indiqué"
          break
        case 'too_much_time_elapsed_since_code_input':
          errorMessage.value =
            'Vous avez rentré ce code il y a trop longtemps, il a expiré'
          break
        default:
          nuxtApp.$airbrakeNotify({
            error: new Error('unhandled error code from backend'),
            params: { response },
            context: { locator: 'burnCode #2' },
          })
          errorMessage.value = GENERIC_ERROR_TEXT
          return
      }
      return
    }

    codeId.value = response.code_id
    proofOfPurchaseNeeded.value = response.proof_of_purchase_needed
    s3UploadImagePath.value = response.s3_upload_image_path
    benefit.value = response.benefit
    imageUrl.value = response.benefit.image_url
    keyForDisplay.value = response.benefit.key_for_display
    codeIsLoaded.value = true
    router.push(localePath('game-1-jslots'))
  }

  function setUserInfo(attachUserInfoArguments: IAttachUserInfoArguments) {
    email.value = attachUserInfoArguments.email
    firstName.value = attachUserInfoArguments.firstName
    postalCode.value = attachUserInfoArguments.postalCode
    city.value = attachUserInfoArguments.city
    lastName.value = attachUserInfoArguments.lastName
    address1.value = attachUserInfoArguments.address1
    address2.value = attachUserInfoArguments.address2
    title.value = attachUserInfoArguments.title
    phoneNumber.value = attachUserInfoArguments.phoneNumber
    termsAccepted.value = attachUserInfoArguments.termsAccepted
  }

  function updateUserInfoFromAuth() {
    const auth = useStoreAuth()

    firstName.value = auth.user?.firstName ? auth.user.firstName : ''
    lastName.value = auth.user?.lastName ? auth.user.lastName : ''
    phoneNumber.value = auth.user?.phone ? auth.user.phone : ''
    email.value = auth.user?.email ? auth.user.email : ''
    title.value = auth.user?.title ? auth.user.title : ''
  }

  const fetchUserAddresses = async () => {
    let data: IAddress[]
    try {
      data = await nuxtApp.$PE.client.listUserInformations()
    } catch (error) {
      nuxtApp.$airbrakeNotify({
        error,
        context: { locator: 'PE.client.listUserInformations' },
      })
      return
    }

    const defaultUserInformation = find(
      data,
      (address) => address.is_user_default,
    )
    if (!defaultUserInformation) return

    address1.value = defaultUserInformation.address_1
    address2.value = defaultUserInformation.address_2
    city.value = defaultUserInformation.city
    postalCode.value = defaultUserInformation.postal_code
  }

  const uploadPictureCode = async () => {
    try {
      if (!filesCodePicture.value || filesCodePicture.value.length === 0) {
        throw new Error('No file selected')
      }

      const file = filesCodePicture.value[0] // Extract the first file from the array

      const nameParts = file.name.split('.')
      const extension = nameParts[nameParts.length - 1]
      const url = `https://purchease-com-user-captures.s3.eu-west-1.amazonaws.com/${s3UploadImagePath.value}.${extension}`
      const aclHeader = 'bucket-owner-full-control'
      const contentTypeHeader = file.type

      const headers = {
        'x-amz-acl': aclHeader,
        'Content-Type': contentTypeHeader,
      }

      const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: file,
      })

      if (!response.ok) {
        throw new Error('Failed to upload image')
      }
      productShotImageKey.value = `${s3UploadImagePath.value}.${extension}`
      return 'success'
    } catch (error) {
      console.error('Error uploading image:', error)
      return 'error'
    }
  }

  const uploadReceipt = async () => {
    const response = await Promise.all(
      receipts.value.map((files) =>
        nuxtApp.$PE.client.uploadWithFiles({ files }),
      ),
    )
    if (response) {
      receiptKitUuid.value = response[0]
      return 'success'
    } else {
      console.error('upload_receipt error')
      return 'error'
    }
  }

  const linkCodeToUserInformationWithProof = async () => {
    try {
      const response = await nuxtApp.$PE.client.linkCodeToUserInformation({
        codeId: codeId.value,
        receiptKitUuid: receiptKitUuid.value,
        productShotImageKey: productShotImageKey.value,
        userInformationFields: {
          email: email.value,
          first_name: firstName.value,
          last_name: lastName.value,
          postal_code: postalCode.value,
          city: city.value,
          address_1: address1.value,
          address_2: address2.value,
          phone_number: phoneNumber.value,
          title: title.value,
        },
      })

      if (response.success) {
        return 'success'
      } else {
        console.error('API call failed:', response.error)
        return 'error'
      }
    } catch (error) {
      console.error('Error making API call:', error)
    }
  }
  const linkCodeToUserInformationWithoutProof = async () => {
    try {
      // Make the API call
      const response = await nuxtApp.$PE.client.linkCodeToUserInformation({
        codeId: codeId.value,
        userInformationFields: {
          email: email.value,
          first_name: firstName.value,
          last_name: lastName.value,
          postal_code: postalCode.value,
          city: city.value,
          address_1: address1.value,
          address_2: address2.value,
          phone_number: phoneNumber.value,
          title: title.value,
        },
      })

      // Handle the response
      if (response.success) {
        return 'success'
      } else {
        console.error('API call failed:', response.error)
        return 'error'
      }
    } catch (error) {
      console.error('Error making API call:', error)
    }
  }

  const callCore = async () => {
    if (proofOfPurchaseNeeded.value === true) {
      const response = await uploadReceipt()
      if (response == 'error') {
        return
      }
      const response2 = await uploadPictureCode()
      if (response2 == 'error') {
        return
      }
      const response3 = await linkCodeToUserInformationWithProof()
      if (response3 == 'error') {
        return
      }
    } else {
      const response = await linkCodeToUserInformationWithoutProof()
      if (response == 'error') {
        return
      }
    }
    isParticipationComplete.value = true
  }

  return {
    $reset,

    code,
    setCode,
    burnCode,

    linkCodeToUserInformationWithProof,
    linkCodeToUserInformationWithoutProof,

    codeId,
    proofOfPurchaseNeeded,
    s3UploadImagePath,
    benefit,
    win,
    errorMessage,
    setUserInfo,
    updateUserInfoFromAuth,
    stepValue,

    email,
    firstName,
    postalCode,
    city,
    lastName,
    address1,
    address2,
    title,
    phoneNumber,
    termsAccepted,

    imageUrl,
    callCore,
    isParticipationComplete,
    isModalOpenProofOfPurchase,
    codeIsLoaded,
    keyForDisplay,
    receipts,
    filesCodePicture,
    fetchUserAddresses,
    isUnfinished,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStoreGame1, import.meta.hot))
}
