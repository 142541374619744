<template>
  <div class="my-5">
    <h5 class="pb-12">Votre panier est vide...</h5>
    <AppButtonSecondary
      :active="false"
      :to="localePath('shop')"
      block
      class="mt-12"
      @click="$emit('close')"
    >
      Aller à la Boutique
    </AppButtonSecondary>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
defineEmits(['close'])
</script>
