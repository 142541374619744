export default defineNuxtRouteMiddleware((_to, from) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, _langage] = String(from.name).split('___')
  if (name !== 'game-2') return

  const store = useStoreGame2()
  if (!store.isParticipationCompleted) return

  store.$reset()
})
