<template>
  <v-container
    fluid
    class="pa-3 pos-relative fill-height align-stretch"
  >
    <img
      class="img-20-years"
      src="~/assets/logos/20-years.svg"
    />
    <img
      class="img-pa"
      src="~/assets/images/landing-pages/tf2/PA.png"
      srcset="
        ~/assets/images/landing-pages/tf2/PA.png    1x,
        ~/assets/images/landing-pages/tf2/PA@2x.png 2x,
        ~/assets/images/landing-pages/tf2/PA@3x.png 3x
      "
    />

    <v-row>
      <v-col
        cols="6"
        class="d-flex flex-column pa-0"
      >
        <LandingPagesTF2DesktopLeft />
      </v-col>
      <v-col
        cols="6"
        class="d-flex flex-column pa-0"
      >
        <LandingPagesTF2DesktopRight />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.pos-relative {
  position: relative;
}
.img-20-years {
  width: 175px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 50px;
}
.img-pa {
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 400px;
}
</style>
