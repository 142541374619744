<template>
  <div>
    <ul class="purchases">
      <li
        v-for="(purchase, index) in basket.purchasesForHeader"
        :key="index"
        class="purchase"
      >
        <AppHeaderRightMenuBasketItem
          :text="purchase.text"
          :image-url="purchase.imageUrl"
          :quantity="purchase.quantity"
          :value="purchase.value"
          removable
          @remove="basket.removePurchase(purchase.identifier)"
        />
      </li>
    </ul>

    <div class="basket__recap">
      <div>Total :</div>
      <div>
        <IconStarSvg
          color="tertiary"
          fill
          :height="14"
        />
        {{ basket.purchasesTotalValue }}
      </div>
    </div>

    <AppButtonSecondary
      :active="false"
      :to="localePath('shop-basket')"
      block
      @click="$emit('close')"
    >
      Voir mon panier
    </AppButtonSecondary>
  </div>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const basket = useStoreBasket()
defineEmits(['close'])
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.purchases {
  list-style: none;
  font-size: 16px;
  line-height: 20px;
}
.purchase + .purchase {
  margin-top: 16px;
}

.basket__recap {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $color-secondary;
  margin: 20px 0;
}
</style>
