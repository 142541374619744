import getBaseUrl from '@/utils/runtime/base-url'

export default defineNuxtPlugin(() => {
  const route = useRoute()
  const baseUrl = getBaseUrl()
  const url = computed(() => `${baseUrl}${route.path}`)
  useSeoMeta({
    ogUrl: url,
    // @ts-ignore
    twitterUrl: url,
  })
})
