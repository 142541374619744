<template>
  <LayoutDefault>
    <!-- hydratation problem without that -->
    <!-- isPurcheaseKitSetup is false on server and true on client -->
    <ClientOnly>
      <div v-if="auth.errorOnKitInitiation">
        <v-container class="px-0">
          Une erreur est survenue
          <v-btn
            :loading="auth.isSettingUpPurcheaseKit"
            size="small"
            class="ml-3"
            @click="auth.setupPurcheaseKit"
          >
            Réessayer
          </v-btn>
        </v-container>
      </div>
      <div v-else-if="auth.isPurcheaseKitSetup">
        <slot />
      </div>
    </ClientOnly>
  </LayoutDefault>
</template>

<script setup lang="ts">
import LayoutDefault from './default.vue'
const auth = useStoreAuth()
</script>
